import axios from 'axios'

const GOOGLE_REGEOCODING = 'https://maps.googleapis.com/maps/api/geocode/json'
const GOOGLE_KEY = 'AIzaSyCztUnbfHJFJQuzP6m8z6J7SUkZcBvNIEM'

const typeKeys: Record<string, any> = {
  city: { key: 'administrative_area_level_2' },
  country: { key: 'country', shortKey: 'country_code' },
  region: { key: 'administrative_area_level_1', shortKey: 'region_short' },
  street: { key: 'route' },
  street_number: { key: 'street_number' },
  zip_code: { key: 'postal_code' }
}

function formatAddress (result: any) {
  const formatted: Record<string, any> = {
    coords: [result.geometry.location.lat, result.geometry.location.lng],
    formatted_address: result.formatted_address,
  }

  for (const key in typeKeys) {
    const component = result.address_components.find((ac: any) => ac.types.includes(typeKeys[key].key))

    if (component) {
      formatted[key] = component.long_name

      if (typeKeys[key].shortKey && component.short_name) {
        formatted[typeKeys[key].shortKey] = component.short_name
      }
    }
  }

  return formatted
}

export async function getAddresses (address: string): Promise<any[]> {
  const addresses: any[] = []

  const { data } = await axios.get(`${GOOGLE_REGEOCODING}?address=${address}&key=${GOOGLE_KEY}&language=pt`)

  if (!data) return addresses

  if (!['OK', 'ZERO_RESULTS'].includes(data.status)) {
    throw new Error(data.status)
  }

  for (const result of data.results) {
    const address = formatAddress(result)
    addresses.push(address)
  }

  return addresses
}
