import clone from 'rfdc'
import { Buffer } from 'buffer'

import { Role } from 'models'
import { QueryOptions } from 'interfaces/queryOptions'

export * from './presentation'

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function buildQueryString (options?: QueryOptions): string {
  if (!options) return ''

  const result: string[] = []

  if (options.attributes) {
    result.push(`attributes=${options.attributes.join(',')}`)
  }

  if (options.includes) {
    for (const include in options.includes) {
      result.push(`includes[${include}]=${options.includes[include].join(',')}`)
    }
  }

  if (options.filters) {
    for (const filter in options.filters) {
      result.push(`filters[${options.filters[filter].key}][${options.filters[filter].op ?? 'eq'}]=${options.filters[filter].value}`)
    }
  }

  if (options.sort) {
    for (const sort in options.sort) {
      result.push(`sort[${sort}]=${options.sort[sort]}`)
    }
  }

  if (options.search) result.push(encodeURI(`search=${options.search}`))
  if (options.limit) result.push(`limit=${options.limit}`)
  if (options.page) result.push(`page=${options.page}`)
  if (options.count) result.push(`count=${options.count}`)

  return result.length ? `?q=${encodeURIComponent(encodeBase64(result.join('&')))}`: ''
}

export function encodeBase64(data: string): string {
  return Buffer.from(data, 'utf8').toString('base64');
}

export function omitBy (obj: Record<string, any>, callback: (value: any) => boolean): Record<string, any> {
  let result: Record<string, any> = {}

  for (const key in obj) {
    if (!callback(obj[key])) {
      result[key] = obj[key]
    }
  }

  return result
}

export function isNil (value: any): boolean {
  return [null, undefined].includes(value)
}

export function pick (obj: Record<string, any>, attributes: string[]): Record<string, any> {
  const result: Record<string, any> = {}
  for (const attribute of attributes) {
    if (!isNil(obj[attribute])) {
      result[attribute] = obj[attribute]
    }
  }
  return result
}

export function isEmpty (obj: Record<string, any>): boolean {
  return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
}

export function cleanAmount(amount: number | string | undefined) {
  if (!amount) return 0

  return Math.floor(parseFloat(amount.toString().replaceAll(',', '.')) * 100)
}

export function cleanPercentageAmount(amount: number | string | undefined) {
  if (!amount) return 0

  return Math.floor(parseFloat(amount.toString().replaceAll(',', '.')))
}

export function deepClone (obj: any): any {
  const obj2 = clone({ proto: true })(obj)
  return obj2
}

export async function checkRoles (requiredRoles: Role[]) {
  const user = JSON.parse(localStorage.getItem('@Softruck:user') ?? '{}')

  requiredRoles.push(Role.ROOT)
  if (!requiredRoles.includes(user.relationships.role.attributes.name)) {
    return Promise.reject({ message: 'Access denied to this role', suggestedMessage: 'Acesso negado' })
  }
}
