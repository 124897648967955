import { createContext } from 'react'

import { User } from 'models/user'
import { Token } from './hooks/useToken'
import { AuthData } from './hooks/useAuthData'
import { ReadResult } from 'interfaces/queryOptions'
import { StaffMember } from 'models'

const AuthContext = createContext({
  user: null as null | ReadResult<StaffMember>,
  initialAuth: async (email: string, password: string) => {},
  login: async (email: string, password: string, verificationCode?: string) => {},
  logout: () => {},
  loading: false,
  token: null as null|Token,
  authData: null as null | AuthData
})

export { AuthContext }
