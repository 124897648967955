import { Plan, UsageType, Role } from 'models'

import { create as baseCreate } from 'services/billing'

import { checkRoles, isNil, omitBy, cleanAmount } from 'utils'

import { AccessPlanForm } from '../types'

export async function create (formData: AccessPlanForm) {
  await checkRoles([Role.MANAGER, Role.SALES])

  const { usage_type } = formData

  if (!usage_type) return

  const data = payloadBuilder[usage_type](formData)

  const response = await baseCreate<Plan>('/access-plans', data)
  return response
}

const payloadBuilder = {
  [UsageType.metered]: (formData: AccessPlanForm) => {
    
    const attributes = {
      ...formData,
      amount: cleanAmount(formData.amount),
      tiers: undefined,
      tier_mode: undefined
    }

    return { attributes: omitBy(attributes, isNil) }
  },
  [UsageType.licensed]: (formData: AccessPlanForm) => {

    const attributes = {
      ...formData,
      amount: cleanAmount(formData.amount),
      tiers: undefined,
      tier_mode: undefined
    }

    return { attributes: omitBy(attributes, isNil) }
  },
  [UsageType.tiered]: (formData: AccessPlanForm) => {
    const attributes = {
      ...formData,
      amount: undefined
    }

    return { attributes: omitBy(attributes, isNil) }
  }
}
