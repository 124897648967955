import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useState, createContext, ReactNode, useCallback } from 'react'

import { StaffMember, StaffPermission } from 'models'
import { StaffMemberRestrictions } from 'models'
import { show, listStaffPermissions } from '../services/api'
import { UseViewStaffProps } from 'modules/Staff/hooks/useViewStaff'
import { ListResult, QueryOptions, ReadResult } from 'interfaces/queryOptions'

type Props = {
  children: ReactNode
}

export const StaffViewContext = createContext<UseViewStaffProps>(
  {} as UseViewStaffProps
)

export function StaffViewProvider ({ children }: Props) {
  const { staffId } = useParams() as unknown as { staffId: string }

  const [isLoadingStaff, setIsLoadingStaff] = useState(true)
  const [staff, setStaff] = useState<ReadResult<StaffMember>>()

  const [isLoadingRestrictions, setIsLoadingRestrictions] = useState(false)
  const [restrictions, setRestrictions] = useState<StaffMemberRestrictions>()

  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false)
  const [permissions, setPermissions] = useState<ListResult<StaffPermission>>({ count: 0, data: [] })

  const fetchStaff = useCallback(
    async () => {

      if (!staffId) return

      setIsLoadingStaff(true)
      try {
        const options = { includes: { role: ['name'] } }

        const data = await show(staffId, options)

        setStaff(data)

        setIsLoadingStaff(false)
      } catch (error: any) {
        setIsLoadingStaff(false)
        toast.error(error.suggestedMessage ?? 'Falha ao recuperar dados do usuário')
      }
    },
    [staffId]
  )

  const showRestrictions = useCallback(
    async () => {

      if (!staffId) return

      setIsLoadingRestrictions(true)
      try {
        const data = await show(staffId)

        setRestrictions(data.attributes.restrictions)

        setIsLoadingRestrictions(false)
      } catch (error: any) {
        setIsLoadingRestrictions(false)
        toast.error(error.suggestedMessage ?? 'Falha ao recuperar restrições do usuário')
      }
    },
    [staffId]
  )

  const listPermissions = useCallback(
    async (options: QueryOptions) => {
      if (!staffId) return

      setIsLoadingPermissions(true)
      try {
        const result = await listStaffPermissions(staffId, options)

        setPermissions(result)

        setIsLoadingPermissions(false)
      } catch (error: any) {
        setIsLoadingPermissions(false)
        toast.error(error.suggestedMessage ?? 'Falha ao buscar permissões do usuário')
      }
    },
    [staffId]
  )

  return (
    <StaffViewContext.Provider
      value={{
        isLoadingStaff,
        staff,
        setStaff,
        fetchStaff,

        isLoadingRestrictions,
        restrictions,
        showRestrictions,

        isLoadingPermissions,
        permissions,
        listPermissions
      }}
    >
      {children}
    </StaffViewContext.Provider>
  )
}

