import { ParsedData } from '../types'
import { validate as validateChip } from '../Chips/validation'
import { validate as validateDevice } from '../Devices/validation'

export function haveErrors (parsed: ParsedData[]) {
  for (const line of parsed) {
    if (!isLineValid(line)) return true
  }

  return false
}

export function parseCSV (content: string, entity: 'chips' | 'devices') {
  const delim = /[,;]/g

  content.slice(0, content.indexOf('\n')) // remove header

  const rows = content.slice(content.indexOf('\n') + 1).split('\n')

  const parsed = rows.map(row => {
    const values = row.replaceAll(/["\r ]/g, '').split(delim)

    const data = entity === 'chips' ? buildChipData(values) : buildDeviceData(values)

    return data
  })

  return parsed
}

function isLineValid (line: ParsedData) {
  for (const key of Object.keys(line)) {
    if (!line[key].valid) return false
  }
  return true
}

function buildChipData (values: string[]) {
  return {
    serial: {
      value: values[0],
      valid: validateChip('serial', values[0])
    },
    number: {
      value: values[1],
      valid: validateChip('number', values[1])
    },
    service_provider: {
      value: values[2],
      valid: validateChip('service_provider', values[2])
    },
    carrier: {
      value: values[3],
      valid: validateChip('carrier', values[3])
    },
    apn: {
      value: values[4],
      valid: validateChip('apn', values[4])
    },
    apn_username: {
      value: values[5],
      valid: validateChip('apn_username', values[5])
    },
    apn_password: {
      value: values[6],
      valid: validateChip('apn_password', values[6])
    }
  }
}

function buildDeviceData (values: string[]) {
  return {
    name: {
      value: values[0],
      valid: validateDevice('name', values[0])
    },
    imei: {
      value: values[1],
      valid: validateDevice('imei', values[1])
    },
    purchase_tax_note: {
      value: values[2],
      valid: validateDevice('purchase_tax_note', values[2])
    },
    purchase_tax_note_date: {
      value: toDate(values[3]).toISOString(),
      valid: validateDevice('purchase_tax_note_date', values[3])
    }
  }
}

function toDate (value: string) {
  const delim = /[/-]/g
  const [val1, val2, val3] = value.split(delim)

  const day = parseInt(val1)
  const month = parseInt(val2)
  const year = parseInt(val3)

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return new Date(1900, 1, 1)
  }

  if (day > 31) { // imply date = YYYY-MM-DD
    return new Date(day, month - 1, year)
  } else {
    return new Date(year, month - 1, day)
  }
}
