export function setDateToUTC(
  date: Date, 
  hours?: number, 
  minutes?: number, 
  seconds?: number, 
  milliseconds?: number
): Date {
  const utcDate = new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    hours ?? date.getUTCHours(),
    minutes ?? date.getUTCMinutes(),
    seconds ?? date.getUTCSeconds(),
    milliseconds ?? date.getUTCMilliseconds()
  ))
  return utcDate
}