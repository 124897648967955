import { buildQueryString } from 'utils'
import { fleetview } from 'services/fleetview'
import { ListResult, QueryOptions, ReadResult, ShowQueryOptions } from 'interfaces/queryOptions'

const BASE_URL = '/v5/billing/users'

export async function create<T> (url: string, data: any): Promise<ReadResult<T>> {
  const response = await fleetview.post(`${BASE_URL}${url}`, { data })
  return response.data.data.attributes["0"]
}

export async function createMany<T> (data: any): Promise<ReadResult<T>[]> {
  const response = await fleetview.post(BASE_URL, { data })
  return response.data.data
}

export async function show<T> (uuid: string, options: ShowQueryOptions = {}): Promise<ReadResult<T>> {
  const optionString = buildQueryString(options).slice(1)
  const q = btoa(optionString)

  const response = await fleetview.get(`${BASE_URL}/${uuid}&q=${q}`)
  return response.data.data
}

export async function list<T> (options: QueryOptions = {}): Promise<ListResult<T>> {
  const response = await fleetview.get(`${BASE_URL}` + buildQueryString(options))
  return response.data
}

export async function edit<T> (id: string, data: any): Promise<ReadResult<T>> {
  const response = await fleetview.patch(`${BASE_URL}/${id}`, { data })
  return response.data.data
}

export async function destroy (id: string): Promise<void> {
  await fleetview.delete(`${BASE_URL}/${id}`)
}

export async function generatePasswordToken (data: any): Promise<string> {
  const response = await fleetview.post(`${BASE_URL}/password-token`, { data })
  return response.data.data.token
}

export async function sendWelcomeEmail (userId: number): Promise<void> {
  await fleetview.post(`/v5/users/send-welcome-email/${userId}`)
}