import { useEffect } from "react";
import { AtSymbolIcon } from "@heroicons/react/outline";
import { useViewStaff } from "../hooks/useViewStaff";
import { PageHeader } from "components/PageHeader";
import { LoadingCircle } from "components/Loading";
import { ViewTabs } from "../components/Tabs";
import { Link } from "react-router-dom";
import { ProtectedSection } from "components/ProtectedSection";
import { Role } from "models";

export function View () {
  const {
    isLoadingStaff,
    staff,
    fetchStaff
  } = useViewStaff()

  useEffect(
    () => {
      if (!staff) {
        fetchStaff()
      }
    },
    [staff, fetchStaff]
  )

  return (
    <>
      <PageHeader title="Usuário" action="Visão Geral" />

      {
        isLoadingStaff
        ? <LoadingCircle />
        : (
          <>
            <div className="px-4">
              <div className="">
                <div className="py-4 md:flex md:items-center md:justify-between">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                            {staff?.attributes.fullname}
                          </h1>
                        </div>
                        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                          <dt className="sr-only">Email</dt>
                          <dd className="flex items-center text-sm text-gray-500 font-medium sm:mr-6">
                            <AtSymbolIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            {staff?.attributes.email}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <ProtectedSection roles={[Role.MANAGER]} id={staff!.id.toString()}>
                    <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                      <Link to={`/staff-members/${staff?.id}/edit/general`}>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                          Editar
                        </button>
                      </Link>
                    </div>
                  </ProtectedSection>
                </div>
              </div>
            </div>
            <div className="px-4">
              <ViewTabs />
            </div>
          </>
        )
      }
    </>
  )
}