import { useURLParams } from "hooks/useURLParams"
import { useViewStaff } from "modules/Staff/hooks/useViewStaff"
import { useHistory } from "react-router-dom"
import { useState, useMemo, useEffect, useCallback } from "react"
import { QueryOptions } from "interfaces/queryOptions"
import config from "config"

import { SearchBar } from "components/Search"
import { LoadingCircle } from "components/Loading"
import { Pagination } from "components/Pagination"

import { translations } from "modules/Staff/translations"
import { ProtectedSection } from "components/ProtectedSection"
import { Role } from "models"
import { DestroyPermissionButton } from "modules/Staff/components/Destroy"
import { PlusIcon } from "@heroicons/react/outline"
import { CreateStaffPermission } from "./components/Create"

export function ClientsTab () {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(
    () => {
      const params: QueryOptions = {
        search: '',
        filters: []
      }

      const search = query.get('search')
      const filters = query.get('q')

      if (search) params.search = search

      if (filters) {
        params.filters = JSON.parse(atob(filters))
      }

      return params
    },
    [query]
  )

  const {
    staff,
    fetchStaff,
    isLoadingPermissions,
    permissions,
    listPermissions
  } = useViewStaff()

  const maxItemsPerPage = 100
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [lastPage, setLastPage] = useState(1)
  const [search, setSearch] = useState(queryParams.search)
  const [filter, setFilter] = useState(queryParams.filters?.length ? queryParams.filters[0].value : '')

  const [ isCreateOpen, setIsCreateOpen ] = useState(false)

  const [options, setOptions] = useState<QueryOptions>({
    includes: { client: ['*'] },
    page: 1,
    count: true,
    limit: maxItemsPerPage,
    search: queryParams.search,
    filters: queryParams.filters,
  })

  useEffect(
    () => {
      if (!staff) {
        fetchStaff()
      }
    },
    [staff, fetchStaff]
  )

  useEffect(
    () => {
      if (!staff) return

      listPermissions(options)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [staff, listPermissions, search, page, filter]
  )

  useEffect(
    () => {
      if (!permissions) return

      setTotal(permissions.count)
    },
    [permissions]
  )

  useEffect(
    () => {
      const newLastPage = Math.ceil(total / maxItemsPerPage)
      setLastPage(newLastPage)
    },
    [maxItemsPerPage, total]
  )

  useEffect(
    () => {
      if (options.filters && options.filters.length > 0) {
        setFilter(options.filters[0].value)
      } else {
        setFilter('')
      }
      setPage(options.page ?? 1)
      setSearch(options.search ?? '')
    },
    [options.filters, options.page, options.search]
  )

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setOptions({ ...options, page: newPage })
    },
    [lastPage, options]
  )

  const onSearch = useCallback(
    (term: string | undefined) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      term === ''
        ? query.delete('search')
        : query.set('search', term)

      const isSettingSearch = options.search === '' && term !== ''
      const isCleaningSearch = options.search !== '' && term === ''
      if (isSettingSearch || isCleaningSearch) {
        options.page = 1
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      options.search = term

      setOptions({ ...options })
    },
    [options, query, replace]
  )

  return (
    <>
      <div>
        <div className="flex items-center">
          <div className="py-6 flex flex-grow flex-row justify-between animate-fade-in-down">
            <SearchBar value={search ?? ''} onChange={onSearch} />
          </div>
          <ProtectedSection roles={[Role.MANAGER]}>
            <button
              type="button"
              onClick={() => setIsCreateOpen(!isCreateOpen)}
              className="h-9 flex items-center justify-center text-sm font-medium rounded-md text-emerald-600 border border-emerald-600 hover:bg-emerald-600 hover:text-white"
            >
              <div className="flex flex-row items-center justify-center px-2">
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
                <div className="mr-2 ml-1">Adicionar cliente </div>
              </div>
            </button>
          </ProtectedSection>
        </div>
          {
            isLoadingPermissions
            ? <div className="py-8"><LoadingCircle /></div>
            : (
              <div>
                <div className="flex flex-col animate-fade-in-down">
                  <div className="">
                    <div className="py-2 inline-block min-w-full">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                Nome
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                Nome fantasia
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                Documento
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                Tipo
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                Segmento
                              </th>
                              <ProtectedSection roles={[Role.MANAGER]}>
                                <th
                                  scope="col"
                                  className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                                >
                                  Ações
                                </th>
                              </ProtectedSection>
                            </tr>
                          </thead>
                          <tbody className="text-left bg-white divide-y divide-gray-200">
                            {permissions?.data.map((permission, idx) => {
                              const clientAttributes = permission?.relationships?.client.attributes
                              return (
                                  <tr key={idx} className={idx % 2 ? 'bg-gray-50' : 'bg-white'}>
                                    <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="flex-1 truncate">
                                          <div className="text-xs text-left font-semibold text-gray-900 hover:text-indigo-900">
                                            { clientAttributes.account_name || '-' }
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                      <div className="flex items-center">
                                          <div className="flex-1 truncate">
                                            <div className="text-xs text-left text-gray-900">
                                              { clientAttributes.fantasy_name || '-' }
                                            </div>
                                          </div>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                      <div className="flex items-center">
                                          <div className="flex-1 truncate">
                                            <div className="text-xs text-left text-gray-900">
                                              { clientAttributes.document || '-' }
                                            </div>
                                            <div className="text-xs text-left text-gray-500">
                                              { translations.document_type[clientAttributes.document_type as string] || '-' }
                                            </div>
                                          </div>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                      <div className="flex items-center">
                                          <div className="flex-1 truncate">
                                            <div className="text-xs text-left text-gray-900">
                                              { clientAttributes.email || '-' }
                                            </div>
                                          </div>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                      <div className="flex items-center">
                                          <div className="flex-1 truncate">
                                            <div className="text-xs text-left text-gray-900">
                                              { translations.type[clientAttributes.type as string] || '-' }
                                            </div>
                                          </div>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                      <div className="flex items-center">
                                          <div className="flex-1 truncate">
                                            <div className="text-xs text-left text-gray-900">
                                            { translations.segment[clientAttributes.segment as string] || '-' }
                                            </div>
                                          </div>
                                        </div>
                                    </td>
                                    <ProtectedSection roles={[Role.MANAGER]}>
                                      <td className="px-4 py-3 text-xs text-left text-gray-500 max-w-auto">
                                        <DestroyPermissionButton staffId={staff!.id} clientId={permission.relationships?.client.id} />
                                      </td>
                                    </ProtectedSection>
                                  </tr>
                              )})}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <Pagination
                  pageSize={maxItemsPerPage}
                  currentPage={page}
                  itemsOnPage={permissions?.data.length ?? 0}
                  totalItems={total}
                  onPageChange={onPageChange}
                />
              </div>
            )
          }
      </div>

      <CreateStaffPermission open={isCreateOpen} setOpen={setIsCreateOpen} staffId={staff!.id.toString()}/>
    </>
  )
}