/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback } from 'react'
import { StatusOfflineIcon, StatusOnlineIcon } from '@heroicons/react/solid'
import { toast } from 'react-toastify'

import { toggle } from '../../services/update'
import { SimpleButton } from 'components/FormInputs/Button'

interface Props {
  planId: number
  isActive: boolean
  onToggle?: (id: number, isActive: boolean) => void
}

export function ToggleActivation ({ planId, isActive, onToggle = () => {} }: Props) {

  const activate = useCallback(async (planId: number) => {
    try {
      const { id, attributes } = await toggle(planId)
      toast.success('Plano ativado com sucesso')
      onToggle(id, attributes.active ?? true)
    } catch (err: any) {
      toast.error(err.suggestedMessage ?? 'Não foi possível ativar o plano')
    }
  }, [onToggle])

  const deactivate = useCallback(async (planId: number) => {
    try {
      const { id, attributes } = await toggle(planId)
      toast.success('Plano desativado com sucesso')
      onToggle(id, attributes.active ?? false)
    } catch (err: any) {
      toast.error(err.suggestedMessage ?? 'Não foi possível desativar o plano')
    }
  }, [onToggle])

  return (
    <>
      {
        isActive
          ? (
            <button
              title='Desativar Plano'
              onClick={() => deactivate(planId)}
              className={"relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500 " + (!isActive ? "": "rounded-r-md")}
            >
              <StatusOfflineIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            )
          : (
            <SimpleButton
              title='Ativar Plano'
              onClick={() => activate(planId)}
              className="relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500 "
            >
              <StatusOnlineIcon className="h-5 w-5" aria-hidden="true" />
            </SimpleButton>
            )
      }
    </>
  )
}
