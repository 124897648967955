export const languages = [
  { value: 'pt_BR', label: 'Português' },
  { value: 'en_US', label: 'English' },
  { value: 'es_UY', label: 'Español' }
]

export const roles = [
  {value: 'ROOT', label: 'Root'},
  {value: 'MANAGER', label: 'Gerente'},
  {value: 'FINANCE', label: 'Financeiro'},
  {value: 'SUPPORT', label: 'Suporte'},
  {value: 'SALES', label: 'Vendas'},
  {value: 'LOGISTICS', label: 'Logística'}
]