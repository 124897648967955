import { Identification } from 'models'

export function cleanDocument (document: string | null | undefined): string {
  if (!document) return ''
  return document.replace(/[ ./-]/g, '')
}

export function cleanPhone (phone: string | null | undefined): string | undefined {
  if (!phone) return
  return phone.replace(/[ .()/-]/g, '')
}

export function cleanUsername (username: string | undefined) {
  if (!username) return

  return username.toLowerCase()
  .replace(/\s+|\/+/g, '')
  .replace(/[àáâãäå]/g, 'a')
  .replace(/[éê]/g, 'e')
  .replace(/[óòõô]/g, 'o')
  .replace(/[úùû]/g, 'u')
  .replace(/[ç]/g, 'c')
}

export function buildCorporateData (data: Identification) {
  if (!data) return

  return {
    corporate_name: data.corporate_name,
    trade_name: data.trade_name,
    corporate_type: data.corporate_type,
    share_capital: data.share_capital,
    legal_nature: data.legal_nature,
    foundation_date: data.foundation_date,
    situation: data.situation?.toLowerCase(),
    situation_date: data.situation_date,
    mien: data.mien?.toLowerCase(),
    main_activity: data.main_activity,
    corporate_structure: data.corporate_structure,
    address: data.address
  }
}

export function filterObjectByValue (obj: Record<string, any>, value: any) {
  const filtered = Object.entries(obj).filter(([key]) => obj[key] === value)
  return Object.fromEntries(filtered)
}

export function buildAccounts (accountTypes: any[] | undefined) {
  const accounts: Record<string, boolean> = { enterprises: true, users: true }

  if (!accountTypes) return accounts

  for (const accountType of accountTypes) {
    accounts[accountType.attributes.name] = true
  }

  return accounts
}