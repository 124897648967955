/* eslint-disable react-hooks/exhaustive-deps */
import Downshift from 'downshift'
import debounce from 'lodash.debounce'
import { useState, useEffect, useCallback } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { StatusOnlineIcon, CheckCircleIcon, BanIcon, StatusOfflineIcon } from '@heroicons/react/outline'

import config from 'config'
import { getDeviceTypes } from '../services/api'
import { DeviceType } from 'modules/Items/types'

interface AutocompleteProps {
  onSelect: (value: any) => any
  settype: (value: any) => any
  [key: string]: any
}

export function Autocomplete ({ onSelect, settype, ...rest }: AutocompleteProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [deviceTypes, setDeviceTypes] = useState([] as DeviceType[])

  const [error, setError] = useState(null as null | string)

  useEffect(() => {
    if (searchValue === '') return

    setError(null)

    search(searchValue)
  }, [searchValue])

  const onClick = (index: number) => {
    const type = deviceTypes[index]

    if (!type.supported) {
      setError('Modelo não suportado')
    }

    settype(type)
    setSearchValue('')
  }

  const search = useCallback(
    debounce(async (search: string): Promise<void> => {
      try {
        if (search.length < config.min_search_length) return

        setIsLoading(true)

        const response = await getDeviceTypes(search)

        const options = response.map((data) => ({
          id: data.id,
          brand_alias: data.brand_alias,
          alias: data.alias,
          supported: data.supported,
          type: data.type
        }))

        setDeviceTypes(options)
        setIsLoading(false)
      } catch (err: any) {
        setIsLoading(false)
        console.error(err)
      }
    }, config.autocomplete_debounce_rate), []
  )

  return (
    <Downshift
      onChange={(selection) =>  selection ? onSelect(searchValue) : undefined}
      itemToString={item => item?.brand_alias ?? ''}
      selectedItem={{ brand_alias: '' }}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen
      }) => (
        <div className="relative">
          <label className="block text-left text-sm font-medium text-gray-700">
            Marca e modelo
          </label>
          <div className="flex items-center">
            <input className={"mt-1 block w-full rounded-md shadow-sm py-2 px-3 border focus:outline-none " + (error ? 'border-red-500 focus:border-red-500' : 'border-gray-300 focus:border-emerald-500')}
              {...getInputProps({
                value: searchValue || '',
                onChange: (e) => { setSearchValue(e.currentTarget.value) },
                ...rest
                })
              }
            />
            { isLoading && (
              <div className="absolute right-1">
                <svg className="animate-spin -ml-1 mr-1 mt-2 h-5 w-5 text-emerald-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            )
          }
          { error && (
            <div className="absolute inset-y-0 right-0 pt-1 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          )}
          </div>
          { error && (
            <p className="absolute left-0 text-xs text-red-500">
              {error}
            </p>
          )}
          <ul className="absolute mt-2 rounded-md bg-white z-20 w-full shadow flex flex-col">
            { isOpen && deviceTypes.length > 0 && (
              <div className="max-h-96 overflow-auto">
                {
                  deviceTypes.map((item, index) => (
                  <div className="flex justify-between px-4 py-2 w-full cursor-pointer bg-white hover:bg-gray-100"
                    {...getItemProps({
                    key: item.id,
                    onClick:() => { onClick(index) },
                    item
                  })}>
                    <div id="device-details">
                      <div id="device-attributes" className='flex flex-row'>
                        <div id="device-image">
                          {
                            (item.supported)
                            ? <StatusOnlineIcon className="w-6 h-6 mt-2 mr-4"/>
                            : <StatusOfflineIcon className="w-6 h-6 mt-2 mr-4"/>
                          }
                        </div>
                        <div id="device-informations">
                          <p className='text-md text-left text-gray-900'>{item.brand_alias}</p>
                          <p className='text-sm text-left text-gray-700'>{item.alias}</p>
                          <p className='text-xs text-left text-gray-700'>{item.type}</p>
                        </div>
                      </div>
                    </div>
                    <div id="device-suport">
                      { (item.supported)
                        ? <CheckCircleIcon className="w-4 h-4 mt-4 mr-2 text-emerald-500"/>
                        : <BanIcon className="w-4 h-4 mt-4 mr-2 text-red-500"/>
                      }
                    </div>
                  </div>
                ))
                }
              </div>
              )
            }
            { isOpen && !deviceTypes.length && (
                <li className="px-4 py-2 text-sm w-full cursor-pointer bg-white hover:bg-gray-100 flex justify-start">
                  Nenhum resultado encontrado
                </li>
              )
            }
          </ul>
        </div>
      )}
    </Downshift>
  )
}