import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { EyeIcon, PencilIcon } from '@heroicons/react/outline'
import { useEffect, useState, useCallback, useMemo } from 'react'

import { Pagination } from 'components/Pagination'
import { PageHeader } from 'components/PageHeader'
import { LoadingCircle } from 'components/Loading'
import { SimpleButton } from 'components/FormInputs/Button'
import { ProtectedSection } from 'components/ProtectedSection'
import { ToggleActivation } from '../components/ToggleActivation'

import config from 'config'
import { Plan, Role } from 'models'
import { list } from '../services/api'
import { amount, currency, date, time } from 'utils'
import { translations } from '../translations'
import { useURLParams } from 'hooks/useURLParams'
import { ReadResult } from 'interfaces/queryOptions'
import { SearchBar } from 'components/Search'
import { extractMetaNames } from '../helpers/extract'

export function List () {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(
    () => {
      const params = {
        search: ''
      }

      const search = query.get('search')

      if (search) params.search = search

      return params
    },
    [query]
  )

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [sort] = useState({})
  const [search, setSearch] = useState(queryParams.search)

  const [total, setTotal] = useState(0)
  const [data, setData] = useState<ReadResult<Plan>[]>([])

  const [isLoading, setIsLoading] = useState(true)

  const maxItemsPerPage = config.items_per_list_page

  useEffect(
    () => {
      async function loadData () {
        setIsLoading(true)

        const options = {
          limit: maxItemsPerPage,
          search,
          page,
          sort
        }

        try {
          const { count, data } = await list(options)
          setData(data)
          setTotal(count)
          setIsLoading(false)
        } catch (err) {
          setIsLoading(true)
          toast.error('Falha ao buscar planos')
          console.error(err)
        }
      }
      loadData()
    },
    [maxItemsPerPage, page, search, sort]
  )

  useEffect(
    () => {
      const newLastPage = Math.ceil(total / maxItemsPerPage)
      setLastPage(newLastPage)
    },
    [maxItemsPerPage, total]
  )

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)
    },
    [lastPage]
  )

  const onToggle = useCallback(
    (id: number, isActive: boolean) => {
      setData(data.map((plan: any) => {
        if (plan.id === id) {
          return {
            ...plan,
            attributes: {
              ...plan.attributes,
              active: isActive
            }
          }
        }
        return plan
      }))
    },
    [data]
  )

  const onSearch = useCallback(
    (term: string) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
      } else {
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setSearch(term)
    },
    [query, replace]
  )

  return (
    <>
      <PageHeader title="Planos" action="Listagem">
        <SearchBar value={search} onChange={onSearch} />
        <ProtectedSection roles={[]}>
          <Link to="/plans/create">
            <SimpleButton>Novo</SimpleButton>
          </Link>
        </ProtectedSection>
      </PageHeader>

      {
        isLoading
        ? (<LoadingCircle />)
        : (
            <div className="flex flex-col animate-fade-in-down">
              <div className="-my-2 overflow-x-auto px-4">
                <div className="py-2 align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            ATIVO
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            NOME
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          >
                            <div className={`flex justify-between items-center`}>
                              <div>
                                TIPO
                              </div>
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            VALOR
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            DURAÇÃO
                          </th>
                          <th
                            scope="col"
                            className="group cursor-pointer px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            NATUREZA
                          </th>
                          <th
                            scope="col"
                            className="group cursor-pointer px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            CRIADO EM
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            AÇÕES
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {
                          data.map((plan, index) => (
                            <tr key={plan.uuid} className={index % 2 === 0 ? 'bg-white': 'bg-gray-50'}>
                              <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                {
                                  plan.attributes.active
                                    ? <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-emerald-100 text-emerald-800">Sim</span>
                                    : <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Não</span>
                                }
                              </td>
                              <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                { plan.attributes.name ?? "-" }
                              </td>
                              <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                <div className="flex">
                                  <div className="flex-1">
                                    <div className="text-xs text-left font-semibold text-gray-900">
                                      { translations.type[plan.attributes.type as string] ?? "-" }
                                    </div>
                                    <div className="text-xs text-left text-gray-500">
                                      { extractMetaNames(plan.attributes.meta) }
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                <div className="flex">
                                  <div className="flex-1">
                                    <div className="text-xs text-left font-semibold text-gray-900">
                                      { `${currency(plan.attributes.currency)} ${amount(plan.attributes.amount)}` }
                                    </div>
                                    <div className="text-xs text-left text-gray-500">
                                      { translations.interval[plan.attributes.interval as string] }
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-3 max-w-xs whitespace-nowrap">
                                <div className="flex items-center">
                                  <div className="flex-1 truncate">
                                    <div className="text-xs text-left text-gray-900 truncate"> { plan.attributes.duration + " meses" } </div>
                                    {/* <div className="text-xs text-left text-gray-500"> { "-" } </div> */}
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap">
                                <div className="text-xs text-left text-gray-900">
                                  { translations.nature[plan.attributes.nature as string] }
                                </div>
                              </td>
                              <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                <div className="flex">
                                  <div className="flex-1">
                                    <div className="text-xs text-left font-semibold text-gray-900">
                                      { date(plan.attributes.created_at) }
                                    </div>
                                    <div className="text-xs text-left text-gray-500">
                                      { time(plan.attributes.created_at) }
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-3 text-xs text-left text-gray-500 max-w-sm">
                                <div className="flex flex-row">
                                  <Link to={`/plans/${plan.id}/view/general`}>
                                    <button
                                      type="button"
                                      className="-ml-px relative inline-flex items-center px-3 py-2 rounded-l-md border-t border-b border-l border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                    >
                                      <EyeIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                  </Link>
                                  {
                                    <ProtectedSection roles={[Role.MANAGER, Role.FINANCE]}>
                                      <ToggleActivation
                                        planId={plan.id}
                                        isActive={plan.attributes.active ?? false}
                                        onToggle={onToggle}
                                      />
                                    </ProtectedSection>
                                  }
                                  {
                                    !plan.attributes.active && (
                                      <ProtectedSection roles={[Role.MANAGER]}>
                                        <Link to={`/plans/${plan.id}`}>
                                          <button
                                            type="button"
                                            className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                          >
                                            <PencilIcon className="h-5 w-5" aria-hidden="true" />
                                          </button>
                                        </Link>
                                      </ProtectedSection>
                                      )
                                  }
                                </div>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="mx-4">
                <Pagination
                  currentPage={page}
                  pageSize={maxItemsPerPage}
                  itemsOnPage={data.length}
                  totalItems={total}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
          )
      }
    </>
  )
}