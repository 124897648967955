import { useCallback, useEffect, useState } from 'react'

import { Close, Button } from 'components/Buttons'
import { CheckBox } from './components/CheckBox'
import { LoadingCircle } from 'components/Loading'

import { document } from 'utils/presentation'
import { useEditClient } from 'modules/Clients/hooks/useEditClient'
import { filterObjectByValue } from 'modules/Clients/helpers/utils'

export function EnterpriseTab() {
  const {
    isSaving,
    isLoadingClient,
    isLoadingEnterprise,
    client, fetchClient,
    enterprise,
    fetchEnterprise,
    createEnterprise,
    editEnterprise, editAccounts,
    accounts, setAccounts
  } = useEditClient()

  const [disabled, setDisabled] = useState(false)
  const [support, setSupport] = useState(false)

  useEffect(
    () => {
      if (!client) {
        fetchClient()
      }
    },
    [client, fetchClient]
  )

  useEffect(
    () => {
      if (!client) return

      if (!enterprise) {
        fetchEnterprise()
      }
    },
    [client, enterprise, fetchEnterprise]
  )

  useEffect(
    () => {
      if (!client || !enterprise) return

      setSupport(enterprise.attributes.support ?? false)

      setDisabled(enterprise && !client.relationships?.enterprise?.id)
    },
    [client, enterprise]
  )

  const onAccountChange = useCallback(
    (type: string) => {
      if (type in accounts) {
        accounts[type] = !accounts[type]
      } else {
        accounts[type] = true
      }

      setAccounts({...accounts})
    },
    [accounts, setAccounts]
  )

  const onCreateEnterprise = useCallback(
    async () => {
      if (!client) return

      const accs = filterObjectByValue(accounts, true)
      const attributes = {
        support,
        accounts: Object.keys(accs)
      }

      await createEnterprise(attributes)
      setSupport(support)
      setDisabled(false)
    },
    [accounts, client, createEnterprise, support]
  )

  const onUpdateEnterprise = useCallback(
    async () => {
      if (!enterprise) return

      if (support !== enterprise.attributes.support) {
        const attributes = { support }
        await editEnterprise(enterprise?.id.toString(), attributes)
        setSupport(support)
      }

      await editAccounts()
    },
    [editAccounts, editEnterprise, enterprise, support]
  )

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()
      if (!client) return

      if (client.relationships?.enterprise?.id && enterprise) {
        await onUpdateEnterprise()
      }

      if (!client.relationships?.enterprise?.id || !enterprise) {
        await onCreateEnterprise()
      }
    },
    [client, enterprise, onCreateEnterprise, onUpdateEnterprise]
  )

  return (
    <>
      <div className="w-10/12 flex flex-row justify-center">
        { isLoadingClient && (
          <div className="my-5">
            <LoadingCircle />
          </div>
          )
        }
      </div>

      {
        !isLoadingClient && (
          <>
            <div className="w-10/12 px-4 mt-10 animate-fade-in-down">
              <div className="border-y rounded-lg shadow flex items-center">
                <div className="px-4 py-5 sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">
                    { client?.attributes.account_name }
                  </h1>
                  <p className="mt-1 text-sm text-gray-700">
                    { document(client?.attributes.document, client?.attributes.document_type) }
                  </p>
                </div>
                <div className="px-4 mt-2 sm:mt-0 sm:ml-16 sm:flex-none">
                  {
                    isLoadingEnterprise
                    ? <LoadingCircle />
                    : client?.relationships?.enterprise?.id
                      ? <span className="px-4 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-emerald-100 text-emerald-800">Vinculada</span>
                      : <span className="px-4 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">Não vinculada</span>
                  }
                </div>
              </div>
            </div>

            {
              !isLoadingEnterprise && (
                <>
                  <div className="w-10/12 mt-5 px-6 animate-fade-in-down">
                    <h4 className="text-lg font-medium text-gray-900">
                      Configurações
                      {!disabled ? "" : <span className="ml-4 items-center text-xs leading-5 text-red-500">Vincule para editar!</span>}
                    </h4>
                    <div className="mt-5">
                      <CheckBox
                        disabled={disabled}
                        name="support"
                        label="Acesso ao suporte"
                        checked={support}
                        onChange={setSupport}
                        description="Ao ativar esta opção, a empresa passará a ter direito ao suporte Softruck"
                      />
                    </div>
                  </div>

                  <div className="w-10/12 px-6 mt-10 animate-fade-in-down">
                    <div className="w-1/2">
                      <h4 className="text-lg font-medium text-gray-900">Tipos de conta</h4>
                    </div>
                    <form onSubmit={(e) => onSubmit(e)} noValidate>
                      <div className="mt-4 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm">
                        <label
                          htmlFor="name"
                          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                        >
                          Básicas
                        </label>
                        <div className="grid grid-cols-5 gap-y-5 gap-x-3 my-2 px-4">
                          <CheckBox
                            disabled={true}
                            name="enterprises"
                            label="Empresas"
                            checked={accounts['enterprises']}
                            onChange={() => onAccountChange('enterprises')}
                          />
                          <CheckBox
                            disabled={true}
                            name="users"
                            label="Usuários"
                            checked={accounts['users']}
                            onChange={() => onAccountChange('users')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="devices"
                            label="Equipamentos"
                            checked={accounts['devices']}
                            onChange={() => onAccountChange('devices')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="chips"
                            label="Chips"
                            checked={accounts['chips']}
                            onChange={() => onAccountChange('chips')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="vehicles"
                            label="Veículos"
                            checked={accounts['vehicles']}
                            onChange={() => onAccountChange('vehicles')}
                          />
                        </div>
                      </div>

                      <div className="mt-5 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm">
                        <label
                          htmlFor="name"
                          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                        >
                          Extras
                        </label>
                        <div className="grid grid-cols-5 gap-y-5 gap-x-3 my-3 px-4">
                          <CheckBox
                            disabled={disabled}
                            name="tracking"
                            label="Mapas"
                            checked={accounts['tracking']}
                            onChange={() => onAccountChange('tracking')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="protocols"
                            label="Protocolos"
                            checked={accounts['protocols']}
                            onChange={() => onAccountChange('protocols')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="trips"
                            label="Viagens"
                            checked={accounts['trips']}
                            onChange={() => onAccountChange('trips')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="fuels"
                            label="Abastecimentos"
                            checked={accounts['fuels']}
                            onChange={() => onAccountChange('fuels')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="identifications"
                            label="Identificações"
                            checked={accounts['identifications']}
                            onChange={() => onAccountChange('identifications')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="sharings"
                            label="Compartilhamentos"
                            checked={accounts['sharings']}
                            onChange={() => onAccountChange('sharings')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="geofences"
                            label="Geocercas"
                            checked={accounts['geofences']}
                            onChange={() => onAccountChange('geofences')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="invitations"
                            label="Convites"
                            checked={accounts['invitations']}
                            onChange={() => onAccountChange('invitations')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="alerts"
                            label="Alertas"
                            checked={accounts['alerts']}
                            onChange={() => onAccountChange('alerts')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="events"
                            label="Eventos"
                            checked={accounts['events']}
                            onChange={() => onAccountChange('events')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="notifications"
                            label="Notificações"
                            checked={accounts['notifications']}
                            onChange={() => onAccountChange('notifications')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="trajectories"
                            label="Histórico"
                            checked={accounts['trajectories']}
                            onChange={() => onAccountChange('trajectories')}
                          />
                          <CheckBox
                            disabled={disabled}
                            name="service_orders"
                            label="Ordem de Serviço"
                            checked={accounts['service_orders']}
                            onChange={() => onAccountChange('service_orders')}
                          />
                        </div>
                      </div>

                      <div className="mt-8 w-full flex justify-end space-x-4 animate-fade-in-down">
                        <Close linkTo="/clients" />

                        {
                          client && client.relationships?.enterprise?.id && enterprise && (
                            <Button
                              disabled={isSaving}
                              type="submit"
                              label="Salvar"
                            />
                          )
                        }
                        {
                          client && !client.relationships?.enterprise?.id && enterprise && (
                            <Button
                              disabled={isSaving}
                              type="submit"
                              label="Vincular"
                            />
                          )
                        }
                        {
                          client && !client.relationships?.enterprise?.id && !enterprise && (
                            <Button
                              disabled={isSaving}
                              type="submit"
                              label="Criar e Vincular"
                            />
                          )
                        }
                      </div>
                    </form>
                  </div>
                </>
              )
            }
          </>
        )
      }
    </>
  )
}