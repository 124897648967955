import { ReactNode } from 'react'
import { AxiosInstance } from 'axios'

import { AuthContext } from '../context'
import { useAuth } from '../hooks/useAuth'

export function AuthProvider ({ children, axiosInstances }: { children: ReactNode, axiosInstances: AxiosInstance[]}) {
  const [
    initialAuth,
    login,
    logout,
    user,
    loading,
    token,
    authData
  ] = useAuth({ axiosInstances })

  const value = { initialAuth, login, logout, user, loading, token, authData }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}