import { EditTabs } from '../components/Tabs/Edit'
import { PageHeader } from 'components/PageHeader'

export function Edit () {
  return (
    <>
      <PageHeader title="Cliente" action="Edição" />

      <div className="px-4">
        <EditTabs />
      </div>
    </>
  )
}
