import { TrashIcon } from "@heroicons/react/outline"
import { Currency, Tier } from "models"
import { amount, currency as currencyMask } from "utils"

interface Prop {
  currency: Currency
  tiers: Tier[]
  setTiers?: (value: any[]) => any
}

export function TierTable({ currency, tiers, setTiers }: Prop) {
  return (
    <div className="mr-4 bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <div className="flex items-start justify-between">
          <h3 className="text-xl text-left leading-6 font-medium text-gray-900">
            Faixas
          </h3>
          <div className="ml-3 h-7 flex items-center">
            {setTiers && (
              <button
                type="button"
                className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                onClick={() => setTiers([])}
              >
                <span className="sr-only">Esvaziar</span>
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
        <p className="max-w-2xl text-sm text-gray-500">Tabela de faixas</p>
      </div>
      {tiers.map((tier, idx) => (
        <div key={idx} className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
              <dt className="text-center text-sm font-medium text-gray-500">{`${
                idx === 0 ? 0 : tiers[idx - 1].up_to + 1
              } - ${tier.up_to}`}</dt>
              <dd className="text-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">{`${currencyMask(
                currency
              )} ${amount(tier.unit_amount)}`}</dd>
            </div>
          </dl>
        </div>
      ))}
    </div>
  )
}
