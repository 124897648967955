import { StringOrNull, Validations } from 'types'

import {
  notEmpty,
  notNullPositiveFloat,
  notNullPositiveInteger
} from 'utils/validations'

const validations: Validations = {
  name: { validate: notEmpty },
  amount: { validate: notNullPositiveFloat },
  duration: { validate: notNullPositiveInteger },
  interval_count: { validate: notNullPositiveInteger },
  unit_amount: { validate: notNullPositiveFloat },
  up_to: { validate: notNullPositiveInteger },
  minimum_invoice_amount: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return 'O campo não pode ser vazio.'

      const number = parseInt(value)

      if (isNaN(number)) return 'O campo deve ser um número.'

      if (number < 0) return 'O campo não pode ser negativo.'

      return null
    }
  },
  fine_amount: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return 'O campo não pode ser vazio.'

      const number = parseInt(value)

      if (isNaN(number)) return 'O campo deve ser um número.'

      if (number < 0) return 'O campo não pode ser negativo.'

      return null
    }
  },
}

export function validate (prop: any, value: StringOrNull): StringOrNull {
  if (!(prop in validations)) return null

  const error = validations[prop].validate(value)
  return error
}
