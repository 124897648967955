/* eslint-disable camelcase */

export type ShippingMethod = 'shipping' | 'pick_up'

export enum OrderStatus {
  pending = 'pending',
  approved = 'approved',
  in_line = 'in_line',
  in_progress = 'in_progress',
  updating_fiscal_data = 'updating_fiscal_data',
  ready_for_pick_up = 'ready_for_pick_up',
  shipping = 'shipping',
  delivered = 'delivered',
  canceled = 'canceled'
}

export type OrderHardwareStatus = 'ordered' | 'canceled' | 'returned' | 'ownership_changed'

export enum OrderConnectivityStatus {
  canceled = 'canceled',
  returned = 'returned',
  ordered = 'ordered'
}

export interface Order {
  id: number
  client_id: number
  plan_id: number
  token: string
  batch: string
  quantity: number
  contract_id?: number
  discount?: number
  shipping_method: ShippingMethod
  ordered_at?: Date | string
  signed_at?: Date
  activated_at?: Date
  end_at?: Date
  created_at: Date
  updated_at: Date
  deleted_at?: Date
  uuid?: string
  minimum_invoice_amount: number | string
  fine_mode: string
  fine_amount: number | string
  relationships?: Record<string, any>
  coupon_id?: number
}
