import { Link } from 'react-router-dom'

import { document } from 'utils'
import { Client } from 'models/client'
import { ReadResult } from 'interfaces/queryOptions'
import { clientStatusMap } from 'modules/Clients/screens/List'
import { ClientStatus } from 'models/clientStatusHistory'
import { translations } from 'modules/Clients/translations'

interface Props {
  client: ReadResult<Client>
}

export function ClientPanel ({ client }: Props) {
  return (
    <div className="bg-white shadow-lg overflow-hidden sm:rounded-lg animate-fade-in-down">
      <div className="flex justify-between items-center">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{client.attributes.account_name}</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{document(client.attributes.document, client.attributes.document_type)}</p>
        </div>
        <div className="mr-6">
          {clientStatusMap[client.relationships?.statuses?.[0].attributes.status as ClientStatus ?? 'pending']}
        </div>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Nome fantasia</dt>
            <dd className="mt-1 text-sm text-gray-900">{client.attributes.fantasy_name}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="mt-1 text-sm text-gray-900">{client.attributes.email}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Tipo</dt>
            <dd className="mt-1 text-sm text-gray-900">{translations.type[client.attributes.type as string]}</dd>
          </div>
          <div className="sm:col-span-2">
            <Link to={`/clients/${client.id}/view/general`}>
              <button
                type="button"
                className="-ml-px relative inline-flex items-center py-2 bg-white text-sm font-medium text-emerald-700 focus:z-10 focus:outline-none"
              >
                Ver detalhes
              </button>
            </Link>
          </div>
        </dl>
      </div>
    </div>
  )
}