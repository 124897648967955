import { ReactNode } from 'react'

interface ButtonProps {
  children: ReactNode
  onClick?: () => any
  className?: string
  [key: string]: any
}

export function SimpleButton ({ children, onClick = () => {}, className, ...rest }: ButtonProps) {
  return (
    <button
      type="button"
      className={className || 'px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none'}
      onClick={onClick}
      { ...rest }
    >
      {children}
    </button>
  )
}
