import Downshift from 'downshift'
import debounce from 'lodash.debounce'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'

import config from 'config'
import { Plan } from 'models'
import { amount, currency } from 'utils'
import { list as listPlans } from '../../../Plans/services/api'
import { list as listAccessPlans } from 'modules/AccessPlans/services/read'
import { translations } from "modules/Orders/translations"
import { ListResult, ReadResult } from 'interfaces/queryOptions'

interface Props {
  clear: boolean
  onChange: (value: any) => any
  onSelect: (value: any) => any
  value?: ReadResult<Plan>
  [key: string]: any
}

export function PlanSearch ({ onSelect, onChange, error, clear, setClear, value, clientCountryId, subscription }: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')

  const [plan, setPlan] = useState<ReadResult<Plan>>()
  const [plans, setPlans] = useState<ListResult<Plan>>()
  const [errors, setErrors] = useState(error)

  const onSearch = useMemo(
    () => debounce(
      async (search: string) => {
        try {
          if (search.length < config.min_search_length) return
          setIsLoading(true)

          const baseFilters = [{ key: 'active', value: true, op: 'eq' }]
          const filters = clientCountryId ? [...baseFilters, { key: 'country_id', op: 'eq', value: clientCountryId }] : baseFilters

          const queryOptions = {
            includes: { country: ['*'] },
            attributes: ['id', 'name', 'type', 'nature', 'amount', 'currency', 'duration', 'interval', 'restricted', 'minimum_invoice_amount', 'fine_mode', 'fine_amount', 'usage_type'],
            filters,
            search
          }

          const result = subscription ? await listAccessPlans(queryOptions) : await listPlans(queryOptions)

          setPlans(result)

          setIsLoading(false)
        } catch (err: any) {
          setIsLoading(false)
          console.error(err)
        }
      },
      config.autocomplete_debounce_rate
    ),
    [clientCountryId]
  )

  useEffect(
    () => {
      if (!value) return

      setPlan(value)
      setSearch(value.attributes.name ?? '')
    },
    [value]
  )

  useEffect(
    () => {
      if (search === '') return

      if (search === plan?.attributes.name) return

      setErrors('')

      onSearch(search)
    },
    [plan, onSearch, search]
  )

  const onClick = useCallback(
    (index: number) => {
      if (!plans) return

      setPlan(plans.data[index])
      setSearch(plans.data[index].attributes.name ?? '')
      onSelect(plans.data[index])
    },
    [plans, onSelect]
  )

  return (
    <div>
      <Downshift
        onChange={(selection) => selection ? onSelect(plan) : undefined}
        itemToString={item => item?.attributes.name ?? ''}
        selectedItem={plan ?? { attributes: { name: '' } }}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen
        }) => (
          <div className="relative">
            <label className="block text-left text-sm font-medium text-gray-700">
              Nome
            </label>
            <div className="relative flex items-center">
              <input className={"mt-1 block w-full rounded-md shadow-sm py-2 px-3 border focus:outline-none " + (error ? 'border-red-500 focus:border-red-500' : 'border-gray-300 focus:border-emerald-500')}
                {...getInputProps({
                  value: clear ? '' : (search || ''),
                  onChange: (e) => { setSearch(e.currentTarget.value) },
                  onFocus: clear ? () => { setPlan(undefined); setSearch(''); setClear(false) } : undefined
                  })
                }
              />
              { isLoading && (
                  <div className="absolute right-1">
                    <svg className="animate-spin mt-1 mr-3 h-5 w-5 text-emerald-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                )
              }
              { error && (
                <div className="absolute inset-y-0 right-0 pt-1 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
                )
              }
            </div>
            { errors && (
              <p className="absolute left-0 text-xs text-red-500">
                {errors}
              </p>
            )}
            <ul className="w-full absolute mt-2 rounded-md bg-white z-20 shadow flex flex-col">
              { isOpen && plans && plans?.data.length > 0 && (
                <div className="max-h-96 overflow-auto">
                  {
                    plans.data.map((item, index) => (
                      <div className="px-4 py-2 w-full cursor-pointer bg-white hover:bg-gray-100 flex justify-between"
                        {...getItemProps({
                          key: item.id,
                          onClick:() => { onClick(index) },
                          item
                        })}
                      >
                        <div>
                          <p className='text-md text-left text-gray-900'>{item.attributes.name}</p>
                          <p className='text-sm text-left text-gray-700'>{`${translations.plan_nature[item.attributes.nature as string]} - ${translations.plan_type[item.attributes.type as string]}`}</p>
                          <p className='text-sm text-left text-gray-700'>{`${currency(item.attributes.currency)} ${amount(item.attributes.amount)} - ${item.attributes.duration} meses`}</p>
                        </div>
                        <div id="device-suport">
                          <CheckCircleIcon className="w-6 h-6 mt-2 mr-2 text-emerald-500"/>
                        </div>
                      </div>
                    ))
                  }
                </div>
                )
              }
              { isOpen && !plans?.data.length && (
                  <li className="px-4 py-2 w-full cursor-pointer bg-white hover:bg-gray-100 flex justify-start">
                    Nenhum resultado encontrado
                  </li>
                )
              }
            </ul>

            <div className="flex pt-4 justify-between gap-8">
              <div className="w-1/2">
                <label className="block text-left text-sm font-medium text-gray-700">Natureza</label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {
                    (plan && plan.id && !clear)
                    ? translations.plan_nature[plan.attributes.nature as string]
                    : ''
                  }
                </div>
              </div>

              <div className="w-1/2">
                <label className="block text-left text-sm font-medium text-gray-700">Tipo</label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {
                    (plan && plan.id && !clear)
                    ? translations.plan_type[plan.attributes.type as string]
                    : ''
                  }
                </div>
              </div>
            </div>

            <div className="flex pt-4 justify-between gap-8">
              <div className="w-1/3">
                <label className="block text-left text-sm font-medium text-gray-700">Valor</label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {
                    (plan && plan.id && !clear)
                    ? `${currency(plan.attributes.currency)} ${amount(plan.attributes.amount)}`
                    : ''
                  }
                </div>
              </div>

              <div className="w-1/3">
                <label className="block text-left text-sm font-medium text-gray-700">Duração</label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {
                    (plan && plan.id && !clear)
                    ? `${plan.attributes.duration} meses`
                    : ''
                  }
                </div>
              </div>

              <div className="w-1/3">
                <label className="block text-left text-sm font-medium text-gray-700">Intervalo de cobrança</label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {
                    (plan && plan.id && !clear)
                    ? translations.plan_interval[plan.attributes.interval as string]
                    : ''
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </Downshift>
    </div>
  )
}
