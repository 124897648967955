import { Plan } from 'models'
import {
  list as baseList,
  show as baseShow,
} from 'services/billing'
import { ListResult, QueryOptions } from 'interfaces/queryOptions'

export async function list (options: QueryOptions = {}): Promise<ListResult<Plan>> {
  const response = await baseList<Plan>('/access-plans', options)
  return response
}

export async function show (id: number | string, options?: Pick<QueryOptions, 'attributes'|'includes'>): Promise<Partial<Plan>> {
  const response = await baseShow<Plan>('/access-plans', id, options)

  return {
    ...response.attributes,
    id: response.id,
    uuid: response.uuid,
    relationships: response.relationships
  }
}
