import { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { SignIn, VerificationCode } from 'modules/Auth'

import { AuthContext } from 'modules/Auth/context'

interface Props {
  [key: string]: any
}

export function LoginRoute ({ ...rest }: Props) {
  const { token, authData } = useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          <Redirect
            to={{
              pathname: rest.location?.state?.from.pathname,
              search: rest.location?.state?.from.search,
              state: {
                from: {
                  ...location,
                  pathname: '/login',
                },
              },
            }}
          />
        ) : authData ? (
          <VerificationCode />
        ) : (
          <SignIn />
        )
      }
    ></Route>
  )
}
