import { Translations } from 'types'

export const translations: Translations = {
  status: {
    active: 'Ativo',
    not_renewed: 'Não renovado',
    overdue: 'Atrasado',
    canceled: 'Cancelado',
    restricted: 'Bloqueado'
  },
  type: {
    enterprise: 'Empresa',
    society: 'Sociedade',
    cooperative: 'Cooperativa',
    individual: 'Indivíduo'
  },
  billing_type: {
    postpaid: 'Pós-pago',
    prepaid: 'Pré-pago'
  },
  interval: {
    day: 'Diária',
    week: 'Semanal',
    month: 'Mensal',
    year: 'Anual'
  },
  tier_mode: {
    discount: 'Desconto',
    graduated: 'Gradual',
    volume: 'Volume'
  },
  segment: {
    transportation: 'Transporte e Logistica',
    education: 'Educação',
    government: 'Governamental',
    retail: 'Varejo',
    services: 'Serviços Gerais',
    trucking: 'Caminhões',
    construction: 'Materiais e construção',
    energy: 'Energia e Utilidades',
    food: 'Comida e Bebida',
    healthcare: 'Saúde',
    tracking: 'Seguro e Rastreamento',
    other: 'Outros'
  },
  address_type: {
    billing: 'Cobrança',
    deliver: 'Entrega',
    other: 'Outro'
  },
  access_plan_type: {
    metered: 'Por consumo',
    licensed: 'Licença',
    tiered: 'Faixas de uso'
  },
  plan_nature: {
    service: 'Serviço',
    good: 'Bem'
  },
  country: {
    Brazil: 'Brasil',
    Uruguay: 'Uruguai',
    Argentina: 'Argentina',
    Chile: 'Chile',
    Mexico: 'México',
    Paraguay: 'Paraguai',
    Venezuela: 'Venezuela',
    Colombia: 'Colômbia',
    Peru: 'Peru',
    Ecuador: 'Equador'
  },
  write_off_motive: {
    rent: 'Aluguel',
    leasing: 'Comodato',
    sale: 'Venda',
    loss: 'Perda',
    asset_sale: 'Venda de ativo',
    returned: 'Retornado'
  },
  order_status: {
    pending: 'Pendente',
    approved: 'Aprovado',
    in_line: 'Na fila',
    in_progress: 'Em progresso',
    updating_fiscal_data: 'Emitindo nota fiscal',
    ready_for_pick_up: 'Pronto para entrega',
    shipping: 'Em envio',
    delivered: 'Entregue',
    canceled: 'Cancelado'
  },
  item_status: {
    ordered: 'Pedido',
    returned: 'Devolvido',
    canceled: 'Cancelado',
    repaired: 'Reparado',
    ownership_changed: 'Vendido'
  }
}
