export type Translations = Record<string, Record<string, string>>

export const translations: Translations = {
  role: {
    ROOT: 'ROOT',
    ADMIN: 'ADMIN',
    MANAGER: 'GERENTE',
    SUPPORT: 'SUPORTE',
    SALES: 'VENDAS',
    ACCOUNT: 'CONTA',
    FINANCE: 'FINANCEIRO',
    LOGISTICS: 'LOGÍSTICA',
    REGULAR: 'CLIENTE',
    OWNER: 'PROPRIETÁRIO',
    ENTERPRISE: 'COLABORADOR',
    VISITOR: 'VISITANTE',
    CALLCENTER: 'OPERADOR',
    PROVIDER: 'PRESTADOR'
  },
  type: {
    enterprise: 'Empresa',
    society: 'Sociedade',
    cooperative: 'Cooperativa',
    individual: 'Indivíduo'
  },
  segment: {
    Transportation: 'Transporte e Logistica',
    Education: 'Educação',
    Government: 'Governamental',
    Retail: 'Varejo',
    Services: 'Serviços Gerais',
    Trucking: 'Caminhões',
    Construction: 'Materiais e construção',
    Energy: 'Energia e Utilidades',
    Food: 'Comida e Bebida',
    Healthcare: 'Saúde',
    Tracking: 'Seguro e Rastreamento',
    Other: 'Outros'
  },
  document_type: {
    cnpj: 'CNPJ'
  },
  languages: {
    pt_BR: 'Português',
    en_US: 'Inglês',
    es_UY: 'Espanhol'
  }
}
