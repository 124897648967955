import { Link } from 'react-router-dom'
import { EyeIcon } from '@heroicons/react/outline'
import { Fragment, useCallback, useEffect, useState } from 'react'

import config from 'config'
import { Order, PlanType } from 'models'
import { Label } from 'components/Label'
import { Pagination } from 'components/Pagination'
import { LoadingCircle } from 'components/Loading'
import { applyDiscount } from 'modules/common/utils'
import { ReadResult } from 'interfaces/queryOptions'
import { amount, classNames, currency, date } from 'utils'
import { translations } from 'modules/Clients/translations'
import { useViewClient } from 'modules/Clients/hooks/useViewClient'

const maxItemsPerPage = config.items_per_list_page

type OrdersList = {
  token: string,
  orders: ReadResult<Order>[]
}

export function OrderTable () {
  const {
    client,
    isLoadingOrders,
    orders, listOrders
  } = useViewClient()

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [lastPage, setLastPage] = useState(1)

  const [data, setData] = useState<OrdersList[]>([])

  useEffect(
    () => {
      if (!client) return

      listOrders(page)
    },
    [listOrders, client, page]
  )

  useEffect(
    () => {
      if (!client || !orders) return

      const results: OrdersList[] = []
      for (const order of orders.data) {
        const ord = results.find((obj: any) => obj.token === order.attributes.token)
        if (!ord) {
          results.push({
            token: order.attributes.token ?? 'Invalid Token',
            orders: [order]
          })
        } else {
          ord.orders.push(order)
        }
      }

      setTotal(orders.count)
      setData(results)
    },
    [client, orders]
  )

  useEffect(
    () => {
      const newLastPage = Math.ceil(total / maxItemsPerPage)
      setLastPage(newLastPage)
    },
    [total]
  )

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)
    },
    [lastPage]
  )

  return (
    <div className="">
      {
        isLoadingOrders
        ? <LoadingCircle />
        : (
          <>
            <div className="flex flex-col animate-fade-in-down">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full">
                      <thead className="bg-white">
                        <tr>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-gray-500">
                            CÓDIGO
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-gray-500">
                            ESTADO
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-gray-500">
                            PLANO
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-gray-500">
                            VALOR UNITÁRIO
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-gray-500">
                            PEDIDO EM
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-gray-500">
                            ATIVADO EM
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-light text-gray-500">
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        { data?.map((order) => (
                          <Fragment key={order.token}>
                            <tr className="border-t border-gray-200">
                              <th
                                colSpan={7}
                                scope="colgroup"
                                className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                              >
                                {order.token}
                              </th>
                            </tr>
                            { order.orders.map((order, index) => (
                              <tr
                                key={order.uuid}
                                className={classNames(index === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                              >
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  <div className="text-xs text-left font-semibold text-gray-500 truncate">
                                    { order.attributes.batch ?? "-" }
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <div className="text-sm text-left font-semibold text-gray-900 truncate">
                                    <Label
                                      type="order_status"
                                      value={order.relationships?.status[0].attributes.status}
                                      label={translations['order_status'][order.relationships?.status[0].attributes.status]}
                                    />
                                  </div>
                                  <div className="ml-2 text-xs text-left text-gray-500">
                                    { date(order.relationships?.status[0].attributes.created_at) }
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <div className="text-sm text-left font-semibold text-gray-900 truncate max-w-xxs">
                                    {order.relationships?.plan.attributes.name}
                                  </div>
                                  <div className="text-xs text-left text-gray-500">
                                    { translations['plan_nature'][order.relationships?.plan.attributes.nature] }
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <div className="text-sm text-left font-semibold text-gray-900 truncate">
                                    { `${currency(order.relationships?.plan.attributes.currency)} ${amount(applyDiscount(order.relationships?.plan.attributes.amount, order.attributes.discount))}` }
                                  </div>
                                  <div className="text-xs text-left text-gray-500">
                                    { `Quantidade: ${order.attributes.quantity}` }
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {date(order.attributes.ordered_at)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {date(order.attributes.activated_at)}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <Link to={`/orders/${order.id}/${order.relationships?.plan.attributes.type === PlanType.connectivity ? 'chips' : 'devices'}`}>
                                    <button
                                      type="button"
                                      className={"relative inline-flex items-center px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500 rounded-md"}
                                    >
                                      <EyeIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <Pagination
              currentPage={page}
              pageSize={maxItemsPerPage}
              itemsOnPage={orders?.data.length ?? 1}
              totalItems={total}
              onPageChange={onPageChange}
            />
          </>
        )
      }
    </div>
  )
}