import { createContext, ReactNode } from 'react'

export interface ShowConfirmationDialogParams {
  title: ReactNode
  message: ReactNode
  onConfirm: () => any
  onCancel?: () => any
}

const ConfirmationContext = createContext({
  showConfirmationDialog: (_params: ShowConfirmationDialogParams) => {}
})

export { ConfirmationContext }
