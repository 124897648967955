import { ReadResult } from "interfaces/queryOptions"
import { Client, Order, OrderConnectivityStatus, OrderHardwareStatus, OrderStatus, Plan, Role } from 'models'
import { FiscalData } from 'models/orderItem'
import {
  edit as baseEdit,
  patch
} from 'services/billing'
import { checkRoles, cleanAmount } from 'utils'
import { ItemFiscalData } from '../types'
import { cleanPercentageAmount } from "utils"

export type EditOrder = {
  order: ReadResult<Order>
  client: ReadResult<Client>
  plan: ReadResult<Plan>
}

const transformAttributes = (attributes: Record<string, any>): Record<string, any> => {
  const {
    minimum_invoice_amount,
    fine_mode,
    fine_amount
  } = attributes

  return {
    minimum_invoice_amount: minimum_invoice_amount === '' ? null : cleanAmount(minimum_invoice_amount),
    fine_mode: fine_amount === '' ? null : fine_mode,
    fine_amount: fine_amount === '' ? null : fine_mode === null ? null :  fine_mode === 'fixed' ? cleanAmount(fine_amount) : cleanPercentageAmount(fine_amount)
  }
}

export async function edit (id: number, { order, client, plan }: EditOrder): Promise<ReadResult<Order>> {
  const { minimum_invoice_amount, fine_mode, fine_amount } = transformAttributes(order.attributes)

  const payload = {
    attributes: {
      quantity: order.attributes.quantity,
      discount: order.attributes.discount,
      shipping_method: order.attributes.shipping_method,
      ordered_at: order.attributes.ordered_at,
      minimum_invoice_amount,
      fine_mode,
      fine_amount
    },
    relationships: {
      client: {
        type: 'client',
        id: client.id
      },
      plan: {
        type: 'plan',
        id: plan.id
      }
    }
  }

  const response = await baseEdit<Order>('/orders', id, payload)
  return response
}

export async function split (id: number, quantities: number[]): Promise<void> {
  await checkRoles([Role.MANAGER, Role.FINANCE, Role.LOGISTICS])
  await patch(`/orders/${id}/split`, { data: { quantities } })
}

export async function updateStatus (id: number | string, status: OrderStatus): Promise<any> {
  await checkRoles([Role.MANAGER, Role.LOGISTICS])
  const { data } = await patch(`/orders/${id}/update-status/${status}`)
  return data
}

export async function approve (id: number | string): Promise<any> {
  await checkRoles([Role.MANAGER, Role.FINANCE])
  const { data } = await patch(`/orders/${id}/approve`)
  return data
}

export async function cancel (id: number | string, fiscalData?: Partial<FiscalData>): Promise<any> {
  await checkRoles([Role.MANAGER, Role.FINANCE])
  const payload = fiscalData ? { attributes: fiscalData } : {}
  const { data } = await patch(`/orders/${id}/cancel`, { data: payload })
  return data
}

export async function deliver (id: number | string): Promise<any> {
  await checkRoles([Role.MANAGER, Role.LOGISTICS])
  const { data } = await patch(`/orders/${id}/deliver`)
  return data
}

export async function updateItemFiscalData (orderId: number, data: ItemFiscalData): Promise<void> {
  await checkRoles([Role.MANAGER, Role.FINANCE, Role.LOGISTICS])
  await patch(`/orders/${orderId}/items/fiscal-data`, { data })
}

export async function updateItemStatus (orderId: number, status: OrderHardwareStatus| OrderConnectivityStatus, data: ItemFiscalData[]): Promise<void> {
  await checkRoles([Role.MANAGER, Role.FINANCE, Role.LOGISTICS])

  const dataWithStatus = data.map((d) => {
    return { attributes: { ...d.attributes, status } }
  })

  await patch(`/orders/${orderId}/items/update-status`, { data: dataWithStatus })
}
