import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { EyeIcon, PencilIcon } from '@heroicons/react/outline'
import { useEffect, useState, useCallback, useMemo } from 'react'

import { Pagination } from 'components/Pagination'
import { PageHeader } from 'components/PageHeader'
import { LoadingCircle } from 'components/Loading'
import { SimpleButton } from 'components/FormInputs/Button'
import { ProtectedSection } from 'components/ProtectedSection'

import config from 'config'
import { Role } from 'models'
import { amount, currency, date, time } from 'utils'
import { useURLParams } from 'hooks/useURLParams'
import { ReadResult } from 'interfaces/queryOptions'
import { SearchBar } from 'components/Search'
import { Coupon } from 'models/coupon'
import { list } from '../services/read'
import { translations } from 'modules/Orders/translations'
import { translations as countryTranslations } from 'modules/Clients/translations'
import { ToggleActivation } from '../components/ToggleActivation'

export function List () {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(
    () => {
      const params = {
        search: ''
      }

      const search = query.get('search')

      if (search) params.search = search

      return params
    },
    [query]
  )

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [sort] = useState({})
  const [search, setSearch] = useState(queryParams.search)

  const [total, setTotal] = useState(0)
  const [data, setData] = useState<ReadResult<Coupon>[]>([])

  const [isLoading, setIsLoading] = useState(true)

  const maxItemsPerPage = config.items_per_list_page

  useEffect(
    () => {
      async function loadData () {
        setIsLoading(true)

        const options = {
          includes: { country: ['name'], orders: ['token'] },
          limit: maxItemsPerPage,
          search,
          page,
          sort
        }

        try {
          const { count, data } = await list(options)
          setData(data)
          setTotal(count)
          setIsLoading(false)
        } catch (err) {
          setIsLoading(true)
          toast.error('Falha ao buscar cupons')
          console.error(err)
        }
      }
      loadData()
    },
    [maxItemsPerPage, page, search, sort]
  )

  useEffect(
    () => {
      const newLastPage = Math.ceil(total / maxItemsPerPage)
      setLastPage(newLastPage)
    },
    [maxItemsPerPage, total]
  )

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)
    },
    [lastPage]
  )

  const onToggle = useCallback(
    (id: number, deactivatedAt: Date | null ) => {
      setData(data.map((coupon: any) => {
        if (coupon.id === id) {
          return {
            ...coupon,
            attributes: {
              ...coupon.attributes,
              deactivated_at: deactivatedAt
            }
          }
        }
        return coupon
      }))
    },
    [data]
  )

  const onSearch = useCallback(
    (term: string) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
      } else {
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setSearch(term)
    },
    [query, replace]
  )

  return (
    <>
      <PageHeader title="Cupons" action="Listagem">
        <SearchBar value={search} onChange={onSearch} />
        <ProtectedSection>
          <Link to="/coupons/create">
            <SimpleButton>Novo</SimpleButton>
          </Link>
        </ProtectedSection>
      </PageHeader>

      {
        isLoading
        ? (<LoadingCircle />)
        : (
            <div className="flex flex-col animate-fade-in-down">
              <div className="-my-2 overflow-x-auto px-4">
                <div className="py-2 align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            ATIVO
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            CÓDIGO
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            NOME
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            TIPO
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            PAÍS
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            APLICAÇÃO
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            VALOR
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            AÇÕES
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {
                          data.map((coupon, index) => (
                            <tr key={coupon.id} className={index % 2 === 0 ? 'bg-white': 'bg-gray-50'}>
                              <td className="px-4 py-3 whitespace-nowrap">
                                <div className="text-xs text-left text-gray-900">
                                  {
                                    coupon.attributes.deactivated_at
                                      ? <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Não</span>
                                      : <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-emerald-100 text-emerald-800">Sim</span>
                                  }
                                </div>
                              </td>
                              <td className="px-4 py-3 text-left text-sm whitespace-nowrap max-w-xxs">
                                <div
                                  className="text-xs text-left font-semibold text-gray-900 truncate"
                                >
                                  { coupon.attributes.token }
                                </div>
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap">
                                <div className="text-xs text-left text-gray-900">
                                  { coupon.attributes.name }
                                </div>
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap">
                                <div className="text-xs text-left font-semibold text-gray-900">
                                  { translations.plan_type[coupon.attributes.type as string] }
                                </div>
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap">
                                <div className="text-xs text-left font-semibold text-gray-900">
                                  { countryTranslations.country[coupon.relationships?.country.attributes.name] }
                                </div>
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap">
                                <div className="flex">
                                  <div className="flex-1">
                                    <div className="text-xs text-left font-semibold text-gray-900"> 
                                      { `Aplicação ${ translations.duration_mode[coupon.attributes.duration_mode as string] }`} 
                                    </div>
                                    { coupon.attributes.duration_mode === 'repeating' && 
                                      <div className="text-xs text-left text-gray-900"> 
                                        { `${coupon.attributes.duration} ${ translations.coupon_interval[coupon.attributes.interval as string] }`} 
                                      </div>
                                    }
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap">
                                <div className="text-xs text-left font-bold text-gray-900">
                                  { 
                                    coupon.attributes.mode === 'fixed'
                                    ? `${currency(coupon.attributes.currency)} ${amount(coupon.attributes.amount)}`
                                    : `${coupon.attributes.amount} %`
                                  }
                                </div>
                              </td>
                              <td className="px-4 py-3 text-xs text-left text-gray-500 max-w-auto">
                                <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.SALES]} id={coupon.id.toString()}>
                                  <div className="flex flex-row">
                                    <Link to={`/coupons/${coupon.id}/view/general`}>
                                      <button
                                        type="button"
                                        className="-ml-px relative inline-flex items-center px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                      >
                                        <EyeIcon className="h-5 w-5" aria-hidden="true" />
                                      </button>
                                    </Link>
                                    <ProtectedSection>
                                      <ToggleActivation
                                        couponId={coupon.id}
                                        deactivatedAt={coupon.attributes.deactivated_at!}
                                        onToggle={onToggle}
                                      />
                                      { !coupon?.relationships?.orders &&
                                        <Link to={`/coupons/${coupon.id}`}>
                                          <button
                                            type="button"
                                            className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                          >
                                            <PencilIcon className="h-5 w-5" aria-hidden="true" />
                                          </button>
                                        </Link>
                                      }
                                    </ProtectedSection>
                                  </div>
                                </ProtectedSection>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="mx-4">
                <Pagination
                  currentPage={page}
                  pageSize={maxItemsPerPage}
                  itemsOnPage={data.length}
                  totalItems={total}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
          )
      }
    </>
  )
}