import { useCallback, useState } from 'react'

import { Modal } from 'components/Modal'
import { Close, Button } from 'components/Buttons'
import { InputWithValidationError } from 'components/FormInputs'

import { Errors } from 'types'
import { User } from 'models/user'
import { phone } from 'utils/presentation'
import { useEditClient } from 'modules/Clients/hooks/useEditClient'
import { validate } from 'modules/Clients/validations/user'
import { check, checkIsFilled, checkIsValid } from 'utils/error'
import languageToLocale from 'utils/locale'

interface Props {
  open: boolean
  setOpen: (open: boolean) => any
}

export function CreateUser ({ open, setOpen }: Props) {
  const { isSaving, createUser, language } = useEditClient()

  const [user, setUser] = useState<Partial<User>>({})
  const [errors, setErrors] = useState<Errors>({})

  const onPhoneChange = useCallback(
    (value: any) => {
      const error = validate('phone', value)

      setUser({ ...user, phone_number: value})
      setErrors({ ...errors, phone: error })
    },
    [errors, user]
  )

  const onAttributeChange = useCallback(
    (attr: keyof User, value: any) => {
      const error = validate(attr, value)

      setUser({ ...user, [attr]: value})
      setErrors({ ...errors, [attr]: error })
    },
    [errors, user]
  )

  const onClose = useCallback(
    () => {
      setUser({})
      setErrors({})
      setOpen(false)
    },
    [setOpen]
  )

  const isFormValid = useCallback(
    (): boolean => {
      const requiredInputs = ['email', 'username', 'name']
      const isFilled = checkIsFilled(user, requiredInputs)
      const isValid = checkIsValid(isFilled)

      setErrors({
        ...errors,
        email: !(isFilled.email) ? 'O campo deve ser preenchido' : null,
        username: !(isFilled.username) ? 'O campo deve ser preenchido' : null,
        name: !(isFilled.name) ? 'O campo deve ser preenchido' : null
      })

      return isValid
    },
    [user, errors]
  )

  const onSave = useCallback(
    async () => {
      if (!user) return

      if (!isFormValid() || check(errors)) return

      const userWithLocale = {
        ...user,
        locale: languageToLocale(language)
      }
      await createUser(userWithLocale)
      onClose()
    },
    [user, errors, createUser, onClose]
  )

  return (
    <Modal open={open} title='Novo usuário' onClose={onClose} width='sm:w-1/2'>
      <div className="mt-4 space-y-5">

        <div className="flex flex-row space-x-4">
          <div className="w-1/2">
            <InputWithValidationError
              label="Login"
              name="login"
              value={user.username}
              onChange={(value: any) => onAttributeChange('username', value)}
              error={errors.username}
            />
          </div>

          <div className="w-1/2">
            <InputWithValidationError
              label="Nome"
              name="name"
              value={user.name}
              onChange={(value: any) => onAttributeChange('name', value)}
              error={errors.name}
            />
          </div>
        </div>

        <div className="flex flex-row space-x-4">
          <div className="w-1/2">
            <InputWithValidationError
              label="Email"
              name="email"
              value={user?.email}
              onChange={(value: any) => onAttributeChange('email', value)}
              error={errors.email}
            />
          </div>

          <div className="w-1/2">
            <InputWithValidationError
              optional="opcional"
              label="Telefone"
              name="phone"
              value={phone(user?.phone_number)}
              onChange={(value) => onPhoneChange(value)}
              error={errors.phone}
            />
          </div>
        </div>
      </div>

      <div className="mt-10 flex justify-end space-x-4">
        <Close onClick={onClose} />
        <Button disabled={isSaving} type="button" onClick={onSave} />
      </div>
    </Modal>
  )
}