import { useEffect } from "react"
import { useViewPlan } from "../hooks/useViewPlan"
import { PageHeader } from "components/PageHeader"
import { LoadingCircle } from "components/Loading"
import { translations } from "../translations"
import { translations as CountryTranslations } from "modules/Clients/translations"
import { amount, currency, date, time } from "utils"
import { ProtectedSection } from "components/ProtectedSection"
import { Role } from "models"
import { Link } from "react-router-dom"
import { extractMetaNames } from "../helpers/extract"

export function View () {
  const {
    isLoadingPlan,
    plan,
    fetchPlan
  } = useViewPlan()

  useEffect(
    () => {
      if (!plan) {
        fetchPlan()
      }
    },
    [plan, fetchPlan]
  )

  return (
    <>
      <PageHeader title="Plano" action="Visão geral"/>

      {
        isLoadingPlan
        ? <LoadingCircle/>
        : (
          <div className="flex flex-row justify-between py-8 animate-fade-in-down">
      <div className="w-1/2">
        <div className="">
          <div className="w-full">
            <div className="flex justify-between">
              <h3 className="ml-4 text-xl leading-6 font-medium text-gray-900">
                { plan?.name }
              </h3>
              <div>
                {
                  plan?.active
                  ? <span className="px-2 inline-flex text-base leading-5 font-semibold rounded-full bg-emerald-100 text-emerald-800">Ativo</span>
                  : <span className="px-2 inline-flex text-base leading-5 font-semibold rounded-full bg-red-100 text-red-800">Não ativo</span>
                }
              </div>
            </div>
            <div className="mt-5 border-t border-gray-200 animate-fade-in-down">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Tipo
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    { translations.type[plan?.type as string] }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Subtipo
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    { extractMetaNames(plan?.meta) }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Tipo de cobrança
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    { translations.billing_type[plan?.billing_type as string] }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    País
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    { CountryTranslations.country[plan?.relationships?.country.attributes.name as string] }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Descrição
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    { plan?.description }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Valor
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    { `${currency(plan?.currency)} ${amount(plan?.amount)}` }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="ml-4 text-sm font-medium text-gray-500">
                  Intervalo
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  { translations.interval[plan?.interval as string] }
                </dd>
              </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Duração
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    { `${plan?.duration} ${plan?.duration?.toString() === '1' ? 'mês' : 'meses'}` }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Natureza
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    { translations.nature[plan?.nature as string]  }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Criado em
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {`${date(plan?.created_at)} ${time(plan?.created_at)}`}
                  </dd>
                </div>
              </dl>
            </div>
            {
              !plan?.active && 
              <ProtectedSection roles={[Role.MANAGER]} id={plan?.id?.toString()}>
                <div className="mt-6 flex justify-center space-x-3 md:mt-0 md:ml-4">
                  <Link to={`/plans/${plan?.id}`}>
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Editar
                    </button>
                  </Link>
                </div>
              </ProtectedSection>
            }
          </div>
        </div>
      </div>
    </div>
        )
      }
    </>
  )
}