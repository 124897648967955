interface Props {
  color?: string
  height?: string
  width?: string
}

export function LoadingCircle ({ color = 'text-emerald-700', height = 'h-6', width = 'w-6'}: Props) {
  return (
    <div className="flex justify-center items-center">
      <svg className={`animate-spin rounded-full ${width} ${height}`} viewBox="0 0 24 24" fill="none">
        <circle className={`opacity-25 stroke-current ${color}`} cx="12" cy="12" r="10" strokeWidth="4"></circle>
        <path className={`opacity-75 fill-current ${color}`} d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  )
}
