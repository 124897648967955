import { useContext } from 'react'

import { StaffMember, StaffMemberRestrictions, StaffPermission } from 'models'
import { ListResult, QueryOptions, ReadResult } from 'interfaces/queryOptions'

import { StaffViewContext } from '../contexts/StaffViewContext'

export type UseViewStaffProps = {
  isLoadingStaff: boolean,
  staff: ReadResult<StaffMember> | undefined,
  setStaff: (data: ReadResult<StaffMember>) => void,
  fetchStaff: () => Promise<void>,

  isLoadingRestrictions: boolean
  restrictions: StaffMemberRestrictions | undefined
  showRestrictions: () => Promise<void>

  isLoadingPermissions: boolean
  permissions: ListResult<StaffPermission> | undefined
  listPermissions: (options: QueryOptions) => Promise<void>
}

export function useViewStaff(): UseViewStaffProps {
  const context = useContext(StaffViewContext)

  if (!context) {
    throw new Error('useShowStaff must be used within an StaffProvider')
  }

  return context
}
