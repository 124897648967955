import { Fragment } from 'react'
import { CheckIcon } from '@heroicons/react/solid'
import { Listbox, Transition } from '@headlessui/react'

import { classNames } from 'utils'
import { ClientStatus } from 'models/clientStatusHistory'
import { statuses } from 'modules/Clients/helpers/constants'

interface Props {
  onChange: (value: any) => any
  value?: number|string
  [key: string]: any
}

type Selected = { value: ClientStatus, label: string }

function getButton (selected: Selected, disabled: boolean) {
  const color = {
    'pending': 'stone',
    'active': 'emerald',
    'canceled': 'red',
    'overdue': 'yellow',
    'not_renewed': 'indigo',
    'restricted': 'orange'
  }

  return (
    <Listbox.Button className={`w-full px-3 py-1 inline-flex items-center text-sm leading-5 font-semibold rounded-full bg-${color[selected.value]}-100 text-${color[selected.value]}-800 ${disabled ? '' : `hover:bg-${color[selected.value]}-200 shadow-md`}`}>
      <svg className={`-ml-0.5 mr-1.5 h-2 w-2 text-${color[selected.value]}-800`} fill="currentColor" viewBox="0 0 8 8">
        <circle cx={4} cy={4} r={3} />
      </svg>
      <span className="block truncate">{selected.label}</span>
    </Listbox.Button>
  )
}

export function StatusSelect ({ onChange, value, disabled, ...rest }: Props) {
  const selected = statuses.find((option) => option.value === value) ?? statuses[0]

  return (
    <Listbox
      disabled={disabled}
      value={selected}
      onChange={(option) => onChange(option.value)}
      {...rest}
    >
      {({ open }) => (
        <>
          <div className="">
            <div className="mt-1 relative">
              { getButton(selected, disabled) }

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
                  {statuses.slice(1).map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-emerald-600' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {option.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-emerald-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        </>
      )}
    </Listbox>
  )
}
