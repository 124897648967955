import { StaffMember } from "models/staffMember"

import { list as baseList, show as baseShow, create as baseCreate, edit as baseEdit, editItem, destroy as baseDestroy, destroyPermission as baseDestroyPermission } from 'services/billing'
import { ListResult, QueryOptions, ReadResult } from 'interfaces/queryOptions'
import { RoleModel, StaffPermission } from "models"
import { Country } from "models/country"

export async function list (options: QueryOptions = {}): Promise<ListResult<StaffMember>> {
  const response = await baseList<StaffMember>('/staff-members', options)
  return response
}

export async function show (staffId: string, options: QueryOptions = {}): Promise<ReadResult<StaffMember>> {
  const response = await baseShow<StaffMember>(`/staff-members`, staffId, options)
  return response
}

export async function createStaff (payload: Record<string, any>): Promise<ReadResult<StaffMember>> {
  const response = await baseCreate<StaffMember>(`/staff-members`, payload)
  return response
}

export async function createStaffPermission (staffId: string, clientId: string): Promise<ReadResult<StaffPermission>> {
  const response = await baseCreate<StaffPermission>(`/staff-members/${staffId}/billing-clients/${clientId}`, {})
  return response
}

export async function listStaffPermissions (staffId: string, options: QueryOptions = {}): Promise<ListResult<StaffPermission>> {
  const response = await baseList<StaffPermission>(`/staff-members/${staffId}/permissions`, options)
  return response
}

export async function listRoles (options: QueryOptions = {}): Promise<ListResult<RoleModel>> {
  const response = await baseList<RoleModel>(`/staff-members/roles`, options)
  return response
}

export async function listCountries (options: QueryOptions = {}): Promise<ListResult<Country>> {
  const response = await baseList<Country>(`/staff-members/countries`, options)
  return response
}

export async function edit (staffId: string, payload: Record<string, any>): Promise<ReadResult<StaffMember>> {
  const response = await baseEdit('/staff-members', staffId, payload)
  return response
}

export async function editStaffRestrictions (staffId: string, payload: Record<string, any>): Promise<ReadResult<StaffMember>> {
  const response = await editItem('/staff-members', 'restrictions', staffId, payload)
  return response
}

export async function destroy (staffId: string | number): Promise<void> {
  const response = await baseDestroy('/staff-members', staffId)
  return response
}

export async function destroyPermission (staffId: string | number, clientId?: string | number): Promise<void> {
  const response = await baseDestroyPermission(staffId, clientId)
  return response
}