import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { EyeIcon, PencilIcon } from '@heroicons/react/outline'
import { useEffect, useState, useCallback, ReactNode, useMemo } from 'react'

import { PageHeader } from 'components/PageHeader'
import { Pagination } from 'components/Pagination'
import { LoadingCircle } from 'components/Loading'
import { SimpleButton } from 'components/FormInputs/Button'
import { ProtectedSection } from 'components/ProtectedSection'

import config from 'config'
import { list } from '../services/read'
import { translations } from '../translations'
import { useURLParams } from 'hooks/useURLParams'
import { QueryFilter, ReadResult } from 'interfaces/queryOptions'
import { Client, ClientStatus, Role } from 'models'
import { billingCycle, date, document, time } from 'utils'
import { ClientStatus as ClientStatusHistory } from 'models/clientStatusHistory'
import { SearchBar } from 'components/Search'
import { SimpleCustomSelect } from 'components/FormInputs'

export const clientStatusMap: Record<ClientStatusHistory, ReactNode> = {
  'pending': <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-stone-100 text-stone-800">Pendente</span>,
  'active': <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-emerald-100 text-emerald-800">Ativo</span>,
  'canceled': <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Cancelado</span>,
  'overdue': <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">Inadimplente</span>,
  'not_renewed': <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full border-2 bg-indigo-100 text-indigo-800">Não renovado</span>,
  'restricted': <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">Bloqueado</span>
}

const statusFilters = [
  { value: '', label: 'Selecione um status' },
  { value: ClientStatus.pending, label: 'Pendente' },
  { value: ClientStatus.active, label: 'Ativo' },
  { value: ClientStatus.canceled, label: 'Cancelado' },
  { value: ClientStatus.overdue, label: 'Inadimplente' },
  { value: ClientStatus.not_renewed, label: 'Não renovado' },
  { value: ClientStatus.restricted, label: 'Bloqueado' } 
]

const removeFilter = (filters: QueryFilter[], key: string, range?: string) => {
  const filtered = [...filters]

  const opMask: Record<string, string> = { to: 'lte', from: 'gte' }

  const filterByKey = (f: QueryFilter) => f.key === key
  const filterByKeyAndOp = (f: QueryFilter) => f.key === key && f.op === opMask[range as string]

  const filterIndex = range
    ? filtered.findIndex(filterByKeyAndOp)
    : filtered.findIndex(filterByKey)

  if (filterIndex >= 0) {
    filtered.splice(filterIndex, 1)
  }

  return filtered
}

export function List () {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(
    () => {
      const params = {
        filters: [],
        search: ''
      }

      const search = query.get('search')
      const filters = query.get('q')

      if (search) params.search = search
      if (filters) params.filters = JSON.parse(atob(filters))

      return params
    },
    [query]
  )

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [search, setSearch] = useState(queryParams.search)
  const [filters, setFilters] = useState<QueryFilter[]>(queryParams.filters)
  const [sort] = useState({ account_name: 1 })
  const [total, setTotal] = useState(0)

  const [data, setData] = useState<ReadResult<Client>[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const maxItemsPerPage = config.items_per_list_page

  useEffect(
    () => {
      async function loadData () {
        setIsLoading(true)

        const options = {
          includes: { statuses: [] },
          limit: maxItemsPerPage,
          search,
          page,
          sort,
          filters
        }

        try {
          const { count, data } = await list<Client>('/clients', options)
          setData(data)
          setTotal(count)
          setIsLoading(false)
        } catch (err) {
          setIsLoading(false)
          toast.error('Falha ao buscar clientes')
          console.error(err)
        }
      }
      loadData()
    },
    [maxItemsPerPage, page, search, sort, filters]
  )

  useEffect(
    () => {
      const newLastPage = Math.ceil(total/ maxItemsPerPage)
        setLastPage(newLastPage)
    },
    [maxItemsPerPage, total]
  )

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)
    },
    [lastPage]
  )

  const onFilter = useCallback(
    (key: string, value: any, range?: string) => {
      const cleanFilters = removeFilter(filters, key, range)

      if (value === '') {
        if (cleanFilters.length > 0) {
          query.set('q', btoa(JSON.stringify(cleanFilters)))
        } else {
          query.delete('q')
        }

        replace({
          pathname: window.location.pathname,
          search: query.toString()
        })

        setFilters([ ...cleanFilters ])
        return
      }

      if (key === 'statuses.status') {
        cleanFilters.push({ key, op: 'eq', value })
      }

      if (cleanFilters.length > 0) {
        query.set('q', btoa(JSON.stringify(cleanFilters)))
      } else {
        query.delete('q')
      }

      setFilters([ ...cleanFilters ])

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })
    },
    [filters, replace, query]
  )

  const onSearch = useCallback(
    (term: string) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
      } else {
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setSearch(term)
    },
    [query, replace]
  )

  return (
    <>
      <PageHeader title="Clientes" action="Listagem">
        <SearchBar value={search} onChange={onSearch} />
        <ProtectedSection roles={[Role.MANAGER, Role.SALES]}>
          <Link to="/clients/create">
            <SimpleButton>Novo</SimpleButton>
          </Link>
        </ProtectedSection>
      </PageHeader>

      <div className="w-full px-4 my-4 h-16 flex items-center justify-between">
        <div className="w-1/5">
          <SimpleCustomSelect
            label=""
            options={statusFilters}
            value={filters?.find((filter) => filter.key === 'statuses.status')?.value ?? statusFilters[0].value}
            onChange={(value) => onFilter('statuses.status', value) }
          />
        </div>
      </div>

      {
        isLoading
        ? (<LoadingCircle />)
        : (
            <div className="flex flex-col animate-fade-in-down">
              <div className="-my-2 overflow-x-auto px-4">
                <div className="py-2 align-middle inline-block min-w-full">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            CONTA
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            EMPRESA
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            TIPO
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          >
                            <div className={`flex justify-between items-center`}>
                              <div>
                                VENCIMENTO
                              </div>
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            CRIADO EM
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            STATUS
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3 text-left text-sm font-light text-gray-500"
                          >
                            AÇÕES
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {
                          data.map((client, index) => (
                            <tr key={client.uuid} className={index % 2 === 0 ? 'bg-white': 'bg-gray-50'}>
                              <td className="px-4 py-3 text-left text-sm whitespace-nowrap max-w-xxs">
                                <div
                                  title={client.attributes.account_name}
                                  className="text-xs text-left font-semibold text-gray-900 truncate"
                                >
                                  { client.attributes.account_name ?? "-" }
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  { client.attributes.email ?? "-" }
                                </div>
                              </td>
                              <td className="px-4 py-3 text-left max-w-xxs text-sm whitespace-nowrap">
                                <div
                                  title={client.attributes.corporate_data?.corporate_name}
                                  className="text-xs text-left font-semibold text-gray-900 truncate"
                                >
                                  { client.attributes.corporate_data?.corporate_name ?? "-" }
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  { document(client.attributes.document, client.attributes.document_type) }
                                </div>
                              </td>
                              <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                <div className="flex">
                                  <div className="flex-1">
                                    <div className="text-xs text-left font-semibold text-gray-900">
                                      { translations.type[client.attributes.type as string] }
                                    </div>
                                    <div className="text-xs text-left text-gray-500">
                                      { translations.segment[client.attributes.segment as string] }
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                { billingCycle(client.attributes.billing_cycle_day) }
                              </td>
                              <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                <div className="flex">
                                  <div className="flex-1">
                                    <div className="text-xs text-left font-semibold text-gray-900">
                                      { date(client.attributes.created_at) }
                                    </div>
                                    <div className="text-xs text-left text-gray-500">
                                      { time(client.attributes.created_at) }
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                { clientStatusMap[client.relationships?.statuses?.[0].attributes.status as ClientStatus] }
                              </td>
                              <td className="px-4 py-3 text-xs text-left text-gray-500 max-w-auto">
                                <div className="flex flex-row">
                                  <Link to={`/clients/${client.id}/view/general`}>
                                    <button
                                      type="button"
                                      className="-ml-px relative inline-flex items-center px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                    >
                                      <EyeIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                  </Link>
                                  <Link to={`/clients/${client.id}/edit/general`}>
                                    <button
                                      type="button"
                                      className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                    >
                                      <PencilIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="mx-4">
                <Pagination
                  currentPage={page}
                  pageSize={maxItemsPerPage}
                  itemsOnPage={data.length}
                  totalItems={total}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
          )
      }
    </>
  )
}