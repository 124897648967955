type StringOrNull = string | null

type Validations = {
  [key: string]: { validate: (value: StringOrNull) => boolean }
}

const patterns = {
  serial: /^\d{20}$/,
  number: /^\d{13}$/
}

const validations: Validations = {
  serial: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return false
      if (!value.match(patterns.serial)) return false
      return true
    }
  },
  number: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return false
      if (!value.match(patterns.number)) return false
      return true
    }
  },
  service_provider: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return false
      return true
    }
  },
  apn: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return false
      return true
    }
  },
  apn_username: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return false
      return true
    }
  },
  apn_password: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return false
      return true
    }
  },
  carrier: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return false
      return true
    }
  }
}

export function validate (prop: any, value: StringOrNull): boolean {
  if (!(prop in validations)) return false

  return validations[prop].validate(value)
}
