import { useContext } from "react"
import { AccessPlanViewContext } from "../contexts/AccessPlanViewContext"
import { Plan } from "models"

export type UseViewAccessPlanProps = {
  isLoadingAccessPlan: boolean,
  accessPlan: Partial<Plan> | undefined,
  setAccessPlan: (data: Partial<Plan>) => void,
  fetchAccessPlan: () => Promise<void>
}

export function useViewAccessPlan(): UseViewAccessPlanProps {
  const context = useContext(AccessPlanViewContext)

  if (!context) {
    throw new Error('useViewAccessPlan must be used within an AccessPlanProvider')
  }

  return context
}