import config from 'config'
import { fleetview } from 'services/fleetview'

import { buildQueryString } from 'utils'
import { QueryOptions, ListResult, ReadResult, ShowQueryOptions } from 'interfaces/queryOptions'

const BASE_URL = '/v5/billing'

export async function list<T> (url: string, options: QueryOptions): Promise<ListResult<T>> {
  options.count = options.count ?? true
  options.limit = options.limit ?? config.items_per_list_page

  const response = await fleetview.get(`${BASE_URL}${url}` + buildQueryString(options))
  return response.data
}

export async function create<T> (url: string, data: Record<string, any>): Promise<ReadResult<T>> {
  const result = await fleetview.post(`${BASE_URL}${url}`, { data })
  return result.data.data
}

export async function createMany<T> (url: string, data: Record<string, any>): Promise<ReadResult<T>[]> {
  const result = await fleetview.post(`${BASE_URL}${url}`, { data })
  return result.data.data
}

export async function show<T> (url: string, id: number | string, options?: ShowQueryOptions): Promise<ReadResult<T>> {
  const result = await fleetview.get(`${BASE_URL}${url}/${id}` + buildQueryString(options))
  return result.data.data
}

export async function edit<T> (url: string, id: number | string, data: any): Promise<ReadResult<T>> {
  const result = await fleetview.patch(`${BASE_URL}${url}/${id}`, { data })
  return result.data.data
}

export async function destroy (url: string, id?: number | string, data?: any): Promise<void> {
  await fleetview.delete(`${BASE_URL}${url}` + (id ? `/${id}` : ''), { data })
}

export async function destroyPermission (staffId: string | number, clientId?: string | number): Promise<void> {
  await fleetview.delete(`${BASE_URL}/staff-members/${staffId}/billing-clients/${clientId}`)
}

export async function patch (url: string, data?: any): Promise<any> {
  const response = await fleetview.patch(`${BASE_URL}${url}`, data)
  return response?.data
}

export async function get (url: string): Promise<any> {
  const response = await fleetview.get(`${BASE_URL}${url}`)
  return response.data
}

export async function post (url: string, data: any): Promise<any> {
  const response = await fleetview.post(`${BASE_URL}${url}`, { data })
  return response.data
}

export async function editItem (url: string, item: string, id: string, data: any): Promise<any> {
  const response = await fleetview.patch(`${BASE_URL}${url}/${id}/${item}`, { data })
  return response.data
}
