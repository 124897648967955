import { Enterprise } from 'models/enterprise'
import { Identification } from 'models/identification'
import { Customization } from 'models/customization'
import { Plan, Client, ClientAddress, Order, User } from 'models'

import {
  list as listBillingApi,
  show as showBillingApi,
  get
} from 'services/billing'
import {
  show as showEnterpriseApi,
  list as listEnterpriseApi
} from 'services/enterprises'
import {
  show as showUserApi,
  list as listUserApi
} from 'services/users'

import { QueryOptions, ListResult, ReadResult } from 'interfaces/queryOptions'

type BaseURL = '/clients' | '/subscriptions' | '/orders'

const urls = {
  users: '/users',
  client: '/clients',
  access: '/access-plans',
  address: '/addresses',
  subscription: '/subscriptions',
  enterprise: '',
  identification: '/identifications',
  customization: '/customizations'
}

export async function list<T> (url: BaseURL, options: QueryOptions = {}): Promise<ListResult<T>> {
  const response = await listBillingApi<T>(url, options)
  return response
}

export async function listClientItems<T> (itemType: 'devices' | 'chips', clientId: number, options: QueryOptions = {}): Promise<ListResult<T>> {
  const url = `/clients/${clientId}/${itemType}`

  const response = await listBillingApi<T>(url, options)
  return response
}

export async function show (id: number, options?: QueryOptions): Promise<ReadResult<Client>> {
  const response = await showBillingApi<Client>(urls.client, id, options)
  return response
}

export async function identify (document: string): Promise<Identification> {
  const { data } = await get(`${urls.identification}/${document}`)
  return data
}

export async function listAddress (options: QueryOptions = {}): Promise<ListResult<ClientAddress>> {
  const response = await listBillingApi<ClientAddress>(urls.address, options)
  return response
}

export async function listPlans (options: QueryOptions = {}): Promise<ListResult<Plan>> {
  const response = await listBillingApi<Plan>(urls.access, options)
  return response
}

export async function listSubscriptions (options: QueryOptions = {}): Promise<ListResult<Order>> {
  const response = await listBillingApi<Order>(urls.subscription, options)
  return response
}

export async function showEnterprise (id: number, options: QueryOptions = {}): Promise<ReadResult<Enterprise>> {
  const response = await showEnterpriseApi<Enterprise>(urls.enterprise, options, id)
  return response
}

export async function listEnterprise (options: QueryOptions = {}): Promise<ListResult<Enterprise>> {
  const response = await listEnterpriseApi<Enterprise>(urls.enterprise, options)
  return response
}

export async function showCustomization (enterpriseUuid: string, customizationUuid: string, options: QueryOptions = {}): Promise<ReadResult<Customization>> {
  const url = `/${enterpriseUuid}${urls.customization}/${customizationUuid}`
  const response = await showEnterpriseApi<Customization>(url, options)
  return response
}

export async function listCustomization (options: QueryOptions = {}): Promise<ListResult<Customization>> {
  const response = await listEnterpriseApi<Customization>(urls.customization, options)
  return response
}

export async function showUser (uuid: string, options: QueryOptions = {}): Promise<ReadResult<User>> {
  const response = await showUserApi<User>(uuid, options)
  return response
}

export async function listUsers (options: QueryOptions = {}): Promise<ListResult<User>> {
  const response = await listUserApi<User>(options)
  return response
}