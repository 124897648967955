import { checkRoles, isNil, omitBy, cleanAmount } from 'utils'
import { Plan, Role } from 'models'

import { patch, edit as baseEdit } from 'services/billing'
import { ReadResult } from 'interfaces/queryOptions'

export async function toggle (id: number): Promise<ReadResult<Plan>> {
  await checkRoles([Role.MANAGER, Role.FINANCE])

  const { data } = await patch(`/access-plans/${id}/toggle`)

  return data
}

export async function edit (id: number, attributes: any): Promise<ReadResult<Plan>> {
  await checkRoles([Role.MANAGER, Role.FINANCE])

  attributes.amount = cleanAmount(attributes.amount)
  const data = {
    attributes: omitBy(attributes, isNil)
  }

  const response = await baseEdit<Plan>('/access-plans', id, data)
  return response
}
