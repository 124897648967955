interface Props {
  name?: string
  label?: string
  checked?: boolean
  description?: string
  disabled?: boolean
  onChange?: (value: boolean) => any
}

export function CheckBox ({ name, label, description, checked, onChange = () => {}, disabled }: Props) {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          disabled={disabled}
          type="checkbox"
          id={name ?? 'checkbox'}
          checked={checked ?? false}
          name={name ?? 'checkbox'}
          onChange={() => onChange(!checked)}
          aria-describedby={`${name ?? 'checkbox'}-description`}
          className="focus:ring-emerald-500 h-4 w-4 text-emerald-700 border-gray-300 rounded"
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={name ?? 'checkbox'} className="font-medium text-gray-700">
          {label ?? 'Checkbox'}
        </label>
        <p id="offers-description" className="text-gray-500">
          {description}
        </p>
      </div>
    </div>
  )
}