import { useEditStaff } from "modules/Staff/hooks/useEditStaff"
import { useState, useEffect, ReactNode, useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import { classNames, deepClone } from "utils"

import { UserTab } from "./Users"
import { RestrictionsTab } from "./Restrictions"
import { ProtectedSection } from "components/ProtectedSection"
import { Role } from "models"

type Tab = {
  id: string
  title: string
  current: boolean
  component: ReactNode
}

const defaultTabs = [
  {
    id: 'general',
    title: "Geral",
    current: false,
    component: <UserTab />
  },
  {
    id: 'restrictions',
    title: "Restrições",
    current: false,
    component: <RestrictionsTab/>
  }
]

export const EditTabs = () => {
  const { replace } = useHistory()

  const { staffId, editTab } = useParams() as unknown as { staffId: string, editTab: string }

  const { staff, fetchStaff } = useEditStaff()

  const [tabs, setTabs] = useState<Tab[]>(defaultTabs)
  const [active, setActive] = useState<Tab>()

  useEffect(
    () => {
      if (!staff) {
        fetchStaff()
      }
    },
    [staff, fetchStaff]
  )

  useEffect(
    () => {
      if (!staff || !editTab) return

      const tabs: Tab[] = deepClone(defaultTabs)

      const index = tabs.findIndex(t => t.id === editTab)
      tabs[index].current = true

      setTabs(tabs)
      setActive(tabs[index])
    },
    [staff, editTab]
  )

  const onTabClick = useCallback(
    (selected: Tab) => {
      if (!staffId) return

      selected.current = true

      setActive(selected)

      replace(`/staff-members/${staffId}/edit/${selected.id}`)
    },
    [staffId, replace]
  )

  const TabButton = ({ tab, onTabClick }: {tab: Tab, onTabClick: (selected: Tab) => void}) => (
    <button
      key={tab.title}
      className={classNames(
        tab.current
          ? 'border-emerald-500 text-emerald-600'
          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
        'whitespace-nowrap justify-between w-1/6 py-3 px-1 border-b-2 font-medium text-sm bg-white'
      )}
      aria-current={tab.current ? 'page' : undefined}
      onClick={() => onTabClick(tab)}
    >
      <div className="flex flex-row justify-center items-center space-x-1">
        <div className="">{tab.title}</div>
      </div>
    </button>
  )

  return (
    <>
      <div>
        <div className="sm:hidden">
        </div>
        <div className="hidden sm:block">
          <div>
            <nav className="flex flex-row w-10/12 border-b border-gray-200" aria-label="Tabs">
              {tabs?.map((tab) => (
                tab.id === 'restrictions' ? (
                  <ProtectedSection roles={[Role.MANAGER]} key={tab.title}>
                    <TabButton tab={tab} onTabClick={onTabClick} />
                  </ProtectedSection>
                ) : (
                  <TabButton key={tab.title} tab={tab} onTabClick={onTabClick} />
                )
              ))}
            </nav>
          </div>
        </div>
      </div>

      { active && active.component }
    </>
  )
}