import { useCallback, useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { AuthContext } from '..'

export function VerificationCode () {
  const [isLoading, setIsLoading] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')

  const context = useContext(AuthContext)
  const email = context.authData?.email
  const password = context.authData?.password

  const onSubmit = useCallback(async (e: any) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      if (email && password && verificationCode) {
        await context.login(email, password, verificationCode)

        setIsLoading(false)
      }
    } catch (err) {
      setIsLoading(false)
      toast.error('Código de verificação inválido.')
    }
  }, [context, email, password, verificationCode])

  const onResendCode = useCallback(async () => {
    setIsLoading(true);

    try {
      if(email && password) {
        // Internal Login route automatically resets Verification Code if no verification code is received
        await context.initialAuth(email, password);

        setIsLoading(false);
        toast.success('Código de verificação reenviado com sucesso.');
        setVerificationCode('')
      }
    } catch (err) {
      setIsLoading(false);
      toast.error('Erro ao reenviar código de verificação.');
    }
  }, [context, email, password]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="softruck-logo.svg"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Softruck Billing</h2>
      </div>

      <div className="m-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white p-8 shadow sm:rounded-md">
          <h2 className="text-center">{`Um código de verificação foi enviado para o email ${email}. Insira-o abaixo para continuar o seu login.`}</h2>
          <form className="space-y-6" onSubmit={onSubmit}>
            <div>
              <label htmlFor="username" className="mt-5 text-center block text-sm font-medium text-gray-700">
                Código de verificação
              </label>
              <div className="mt-1">
                <input
                  id="verification_code"
                  name="verification_code"
                  type="text"
                  autoComplete="off"
                  required
                  onChange={(e) => setVerificationCode(e.target.value)}
                  value={verificationCode}
                  className="text-center appearance-none block w-full p-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-emerald-600 focus:ring-1 focus:border-emerald-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                // onKeyPress={(e: any) => e.key === "Enter" ? onAddToCart(currentSerial) : null}
                className="w-full flex justify-center p-3 rounded-md text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
                { isLoading
                  ? (
                    <div className="flex justify-center items-center">
                      <svg className="animate-spin rounded-full h-5 w-5" viewBox="0 0 24 24" fill="none">
                        <circle className="opacity-25 stroke-current text-white" cx="12" cy="12" r="10" strokeWidth="4"></circle>
                        <path className="opacity-75 fill-current text-white" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                  )
                  : 'Entrar'
                }
              </button>
            </div>
          </form>
          <div>
            <button
              type="button"
              onClick={onResendCode}
              // onKeyPress={(e: any) => e.key === "Enter" ? onAddToCart(currentSerial) : null}
              className="w-full flex justify-center p-3 rounded-md mt-2 text-sm text-emerald-600 font-medium text-white bg-white hover:outline focus:outline-emerald-700 focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
            >
              { isLoading
                ? (
                  <div className="flex justify-center items-center">
                    <svg className="animate-spin rounded-full h-5 w-5" viewBox="0 0 24 24" fill="none">
                      <circle className="opacity-25 stroke-current text-white" cx="12" cy="12" r="10" strokeWidth="4"></circle>
                      <path className="opacity-75 fill-current text-white" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                )
                : 'Reenviar código'
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
