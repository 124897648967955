import { useViewStaff } from "modules/Staff/hooks/useViewStaff";
import { useEffect } from "react";

export function RestrictionsTab () {
  const daysNames: string[] = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"]

  const {
    restrictions,
    showRestrictions
  } = useViewStaff()

  useEffect(
    () => {
      if(!restrictions) {
        showRestrictions()
      }
    },
    [restrictions, showRestrictions]
  )

  return (
    <div className="flex flex-row justify-between py-8 animate-fade-in-down">
      <div className="w-1/2">
        <div className="">
          <div className="w-full">
            <div>
              <h3 className="ml-4 text-xl leading-6 font-medium text-gray-900">
                Restrições de acesso
              </h3>
            </div>
            <div className="mt-5 border-t border-gray-200 animate-fade-in-down">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Dias da semana
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    { 
                      restrictions?.allowed_days
                      .split('')
                      .map((day, index) => day === '1' ? daysNames[index] : '')
                      .filter(day => day !== '')
                      .join(', ')
                    }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Horário de início
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    { restrictions?.allowed_start_time }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Horário de fim
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  { restrictions?.allowed_end_time }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Países
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {restrictions?.allowed_countries && restrictions.allowed_countries.join(', ')}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    IPs permitidos
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {restrictions?.allowed_ip_ranges && restrictions.allowed_ip_ranges.join(', ')}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}