import { useHistory } from 'react-router-dom'

import { destroy } from '../../services'
import { Destroy } from 'components/Buttons/Destroy'

export function DestroyButton ({ id }: { id: number }) {
  const history = useHistory()

  return (
    <Destroy
      id={id}
      destroy={destroy}
      successMessage='Pedido excluído com sucesso'
      onDestroy={() => history.push('/orders')}
    />
  )
}
