import { createContext, ReactNode, useCallback, useState } from "react"
import { UseEditStaffProps } from '../hooks/useEditStaff'
import { useParams } from "react-router-dom"
import { ListResult, ReadResult } from "interfaces/queryOptions"
import { RoleModel, StaffMember, StaffMemberRestrictions } from "models"
import { toast } from "react-toastify"
import { deepClone } from "utils"

import { show, edit, editStaffRestrictions, listRoles, listCountries } from "../services/api"
import { Country } from "models/country"

type Props = {
  children: ReactNode
}

export const StaffEditContext = createContext<UseEditStaffProps>(
  {} as UseEditStaffProps
)

export function StaffEditProvider ({ children }: Props) {
  const { staffId } = useParams() as unknown as { staffId: string }

  const [isSaving, setIsSaving] = useState(false)

  const [isLoadingStaff, setIsLoadingStaff] = useState(true)
  const [staff, setStaff] = useState<ReadResult<StaffMember>>()

  const [isLoadingRestrictions, setIsLoadingRestrictions] = useState(false)
  const [restrictions, setRestrictions] = useState<StaffMemberRestrictions>()

  const [isLoadingRoles, setIsLoadingRoles] = useState(false)
  const [roles, setRoles] = useState<ListResult<RoleModel>>({count: 0, data: []})

  const [countries, setCountries] = useState<ListResult<Country>>({count: 0, data: []})

  const fetchStaff = useCallback(
    async () => {
      if (!staffId) return

      setIsLoadingStaff(true)
      try {
        const data = await show(staffId, {
          includes: {
            role: ['name']
          }
        })

        setStaff(data)

        setIsLoadingStaff(false)
      } catch (error: any) {
        setIsLoadingStaff(false)
        toast.error(error.suggestedMessage ?? 'Falha ao recuperar dados do usuário')
      }
    }, 
    [staffId]
  )

  const showRestrictions = useCallback(
    async() => {
      if (!staff) return

      setIsLoadingRestrictions(true)
      try {
        const data = await show(staffId)
        const countriesList = await listCountries(
          { sort: { name: 1 } }
        )

        const restrictions = data.attributes.restrictions as any

        let ipsString = ""

        if(restrictions){
          ipsString = joinStringsArray(restrictions.allowed_ip_ranges)

          restrictions.allowed_ip_ranges = ipsString
        }

        setRestrictions(restrictions)
        setCountries(countriesList)

        setIsLoadingRestrictions(false)
      } catch (error: any) {
        setIsLoadingRestrictions(false)
        toast.error(error.suggestedMessage ?? 'Falha ao recuperar restrições do usuário')
      }
    },
    [staffId, staff]
  )

  const fetchRoles = useCallback(
    async() => {
      if (!staff) return

      setIsLoadingRoles(true)

      const options = {
        attributes: ['name']
      }
      try {
        const result = await listRoles(options)

        setRoles(result)

        setIsLoadingRoles(false)
      } catch (error: any) {
        setIsLoadingRoles(false)
        toast.error(error.suggestedMessage ?? 'Falha ao recuperar papéis')
      }
    },
    [staff]
  )

  const editStaff = useCallback(
    async() => {
      if (!staff) return

      setIsSaving(true)
      try {
        const payload = deepClone(staff.attributes)
        payload.updated_at = undefined

        await edit (staffId, {
          attributes: {
            email: payload.email,
            fullname: payload.fullname,
            language: payload.language,
            phone_number: payload.phone_number
          },
          relationships: {
            role: {
              type: 'role',
              id: payload.role_id ? payload.role_id : staff.relationships?.role.id
            }
          }
        })

        toast.success('Usuário editado com sucesso')
        setIsSaving(false)
      } catch (error: any) {
        setIsSaving(false)
        toast.error(error.suggestedMessage ?? 'Houve uma falha ao tentar editar o usuário')
      }
    },
    [staffId, staff]
  )

  const editRestrictions = useCallback(
    async() => {
      if (!restrictions) return

      setIsSaving(true)
      try {
        const payload = staff?.attributes.restrictions
        
        await editStaffRestrictions(staffId, {
          attributes: {
            restrictions: payload
          }
        })

        toast.success('Restrições do usuário editadas com sucesso')
        setIsSaving(false)
      } catch (error: any) {
        setIsSaving(false)
        toast.error(error.suggestedMessage ?? 'Houve uma falha ao tentar editar as restrições do usuário')
      }
    },
    [restrictions, staff, staffId]
  )

  function joinStringsArray(arr: string[]): string {
    return arr.join(', ');
  }

  return (
    <StaffEditContext.Provider
      value = {{
        isSaving,
        isLoadingStaff,
        staff,
        setStaff,
        staffId,
        fetchStaff,
        editStaff,
        showRestrictions,
        editRestrictions,
        restrictions,
        isLoadingRestrictions,
        roles,
        fetchRoles,
        isLoadingRoles,
        countries
      }}
    >
      {children}
    </StaffEditContext.Provider>
  )
}