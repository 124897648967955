import { buildQueryString } from 'utils'
import { fleetview } from 'services/fleetview'
import { ListResult, QueryOptions, ReadResult, ShowQueryOptions } from 'interfaces/queryOptions'

const BASE_URL = '/v5/billing/enterprises'

export async function create<T> (url: string, data: any): Promise<ReadResult<T>> {
  const response = await fleetview.post(`${BASE_URL}${url}`, { data })
  return response.data.data
}

export async function show<T> (url: string, options: ShowQueryOptions = {}, id?: number): Promise<ReadResult<T>> {
  const baseUrl = id != null
    ? `${BASE_URL}${url}/${id}`
    : `${BASE_URL}${url}`

  const response = await fleetview.get(`${baseUrl}` + buildQueryString(options))
  return response.data.data
}

export async function list<T> (url: string, options: QueryOptions = {}): Promise<ListResult<T>> {
  const response = await fleetview.get(`${BASE_URL}${url}` + buildQueryString(options))
  return response.data
}

export async function edit<T> (url: string, data: any, id?: string): Promise<ReadResult<T>> {
  const baseUrl = id
    ? `${BASE_URL}${url}/${id}`
    : `${BASE_URL}${url}`

  const response = await fleetview.patch(baseUrl, { data })
  return response.data.data
}
