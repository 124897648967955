import { QueryOptions } from 'interfaces/queryOptions'
import { list as baseList, createBatch, listDeviceTypes } from 'services/devices'

export interface DeviceListResponse {
  id: number
  enterprise?: string
  imei?: string
  name?: string
  nature?: string
  brand_alias?: string
  alias?: string
  last_connection_time?: Date
  last_gps_time?: Date
}

export interface ListResponse {
  count: number
  devices: DeviceListResponse[]
}

export const list = async (queryOptions: QueryOptions): Promise<ListResponse> => {
  const { data, count } = await baseList(queryOptions)

  const devices = data.map((device) => {
    const deviceAttributes = device.attributes.attributes
    const deviceTypeAttributes = device.relationships?.type?.attributes.attributes ?? {}
    const deviceLastGpsAttributes = device.relationships?.last_gps?.attributes.attributes ?? {}
    const enterpriseAttributes = device.relationships?.enterprise?.attributes.attributes ?? {}

    return ({
      id: device.id,
      enterprise: enterpriseAttributes.name,
      imei: deviceAttributes.imei,
      name: deviceAttributes.name,
      nature: deviceAttributes.nature,
      brand_alias: deviceTypeAttributes.brand_alias,
      alias: deviceTypeAttributes.alias,
      last_connection_time: deviceLastGpsAttributes.last_connection_time,
      last_gps_time: deviceLastGpsAttributes.last_gps_time
    })
  })

  return { count, devices }
}

export const create = async (attributes: Record<string,any>[]): Promise<void> => {
  if (!attributes.length) return

  const payload = attributes.map(({ device_type_id, ...attr }) => ({
    attributes: attr,
    relationships: {
      type: {
        type: 'type',
        id: device_type_id
      }
    }
  }))

  await createBatch(payload)
}

export const getDeviceTypes = async (search: string): Promise<[{ id: number, brand_alias: string, alias: string, supported: false, type: string }]> => {
  const deviceTypes = await listDeviceTypes({
    attributes: ['id', 'brand_alias', 'alias', 'supported', 'type'],
    search
  })

  return deviceTypes.map((dt: Record<string, any>) => {
    const deviceTypeAttributes = dt.attributes.attributes

    return ({
      id: dt.id,
      brand_alias: deviceTypeAttributes.brand_alias,
      alias: deviceTypeAttributes.alias,
      type: deviceTypeAttributes.type,
      supported: deviceTypeAttributes.supported
    })
  })
}
