import { WizardSteps } from 'models/client'

const DEFAULT_WIZARD = '100000'

const tabs = ['client','address','subscription','enterprise','customization','user']

export function completeStep (wizard: string | undefined, step: WizardSteps) {
  if (!wizard) return DEFAULT_WIZARD

  const wiz = wizard.split('')
  wiz[tabs.indexOf(step)] = '1'

  return wiz.join('')
}

export function isStepCompleted (wizard: string | undefined, step: WizardSteps) {
  if (!wizard) return false
  const wiz = wizard.split('')
  return wiz[tabs.indexOf(step)] === '1'
}

export function isWizardCompleted (wizard: string | undefined) {
  if (!wizard) return false
  const wiz = wizard.split('')
  return wiz.every(step => step === '1')
}