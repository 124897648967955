import { Order } from 'models'
import {
  list as baseList,
  show as baseShow
} from 'services/billing'
import { QueryOptions, ListResult, ReadResult } from 'interfaces/queryOptions'

export async function list (options: QueryOptions = {}): Promise<ListResult<Order>> {
  const response = await baseList<Order>('/orders', options)
  return response
}

export async function newShow (id: number, options?: Pick<QueryOptions, 'attributes'|'includes'>): Promise<ReadResult<Order>> {
  const response = await baseShow<Order>('/orders', id, options)
  return response
}

export async function show (id: number, options?: Pick<QueryOptions, 'attributes'|'includes'>): Promise<Partial<Order>> {
  const response = await baseShow<Order>('/orders', id, options)
  return {
    ...response.attributes,
    id: response.id,
    uuid: response.uuid,
    client_id: response.relationships?.client?.id,
    plan_id: response.relationships?.plan?.id,
    relationships: response.relationships
  }
}

export async function listItems (orderId: number, options: QueryOptions = {}): Promise<any> {
  const items = await baseList(`/orders/${orderId}/items`, options)
  return items
}