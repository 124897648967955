import { useContext } from "react"
import { PlanViewContext } from "../contexts/PlanViewContext"
import { Plan } from "models"

export type UseViewPlanProps = {
  isLoadingPlan: boolean,
  plan: Partial<Plan> | undefined,
  setPlan: (data: Partial<Plan>) => void,
  fetchPlan: () => Promise<void>
}

export function useViewPlan(): UseViewPlanProps {
  const context = useContext(PlanViewContext)

  if (!context) {
    throw new Error('useViewPlan must be used within an PlanProvider')
  }

  return context
}