import { Role } from 'models'

import { destroy as baseDestroy } from 'services/billing'
import { destroy as baseDestroyUser } from 'services/users'
import { checkRoles } from 'utils'


export async function destroy (id: number): Promise<void> {
  await checkRoles([Role.MANAGER, Role.FINANCE])
  await baseDestroy('/clients', id)
}

export async function destroyAddress (id: number): Promise<void> {
  await baseDestroy('/addresses', id)
}

export async function destroyUser (id: string): Promise<void> {
  await baseDestroyUser(id)
}