import { Role } from 'models'
import { checkRoles } from 'utils'
import { destroy as baseDestroy } from 'services/billing'

export async function destroy (id: number | string): Promise<void> {
  await checkRoles([Role.MANAGER, Role.FINANCE])
  await baseDestroy('/orders', id)
}

export async function removeItems (orderId: number, itemIds: number[]): Promise<void>{
  await checkRoles([Role.MANAGER, Role.LOGISTICS])
  await baseDestroy(`/orders/${orderId}/items`, undefined, { data: { item_ids: itemIds } })
}

