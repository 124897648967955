import { ReactNode, useContext } from 'react'

import { Role } from 'models'
import { AuthContext } from 'modules/Auth'

interface RestrictedAccessProps {
  children: ReactNode
  roles?: Role[]
  id?: string
}

export function ProtectedSection ({ roles, children, id }: RestrictedAccessProps) {
  const { user } = useContext(AuthContext)

  const rolesWithAccess = roles ?? Object.keys(Role)
  rolesWithAccess.push(Role.ROOT)

  return (
    <>
      {(user?.relationships?.role.attributes.name && rolesWithAccess.includes(user.relationships.role.attributes.name) || id === user?.id) && children}
    </>
  )
}
