import { ListResult, ReadResult } from "interfaces/queryOptions"
import { StaffEditContext } from "../contexts/StaffEditContext"

import { useContext } from "react"
import { RoleModel, StaffMember, StaffMemberRestrictions } from "models"
import { Country } from "models/country"

export type UseEditStaffProps = {
  isSaving: boolean
  isLoadingStaff: boolean
  staff: ReadResult<StaffMember> | undefined
  staffId: string
  setStaff: (data: ReadResult<StaffMember>) => void
  fetchStaff: () => Promise<void>
  editStaff: () => Promise<Record<string, string> | void>
  isLoadingRestrictions: boolean
  restrictions: StaffMemberRestrictions | undefined
  showRestrictions: () => Promise<void>
  editRestrictions: () => Promise<Record<string, any> | void>
  roles: ListResult<RoleModel>
  fetchRoles: () => Promise<void> 
  isLoadingRoles: boolean
  countries: ListResult<Country>
}

export function useEditStaff(): UseEditStaffProps {
  const context = useContext(StaffEditContext)

  if (!context) {
    throw new Error('useEditStaff must be used within an StaffProvider')
  }

  return context
}