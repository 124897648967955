import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useState, ReactNode, useCallback } from 'react'

import { classNames, deepClone } from 'utils'

import { ChipTab } from './Chips'
import { OrderTab } from './Orders'
import { DeviceTab } from './Devices'
import { OverallTab } from './Overall'
import { SubscriptionTab } from './Subscription'

type Tab = {
  id: string
  title: string
  current: boolean
  component: ReactNode
}

const defaultTabs = [
  {
    id: 'general',
    title: "Geral",
    current: false,
    component: <OverallTab />
  },
  {
    id: 'subscription',
    title: "Assinaturas",
    current: false,
    component: <SubscriptionTab />
  },
  {
    id: 'orders',
    title: "Pedidos",
    current: false,
    component: <OrderTab />
  },
  {
    id: 'devices',
    title: "Rastreadores",
    current: false,
    component: <DeviceTab />
  },
  {
    id: 'chips',
    title: "Chips",
    current: false,
    component: <ChipTab />
  }
]

export const ViewTabs = () => {
  const { replace } = useHistory()

  const { clientId, viewTab } = useParams() as unknown as { clientId: number, viewTab: string }

  const [tabs, setTabs] = useState<Tab[]>(defaultTabs)
  const [active, setActive] = useState<Tab>()

  useEffect(
    () => {
      if (!clientId || !viewTab) return

      const tabs: Tab[] = deepClone(defaultTabs)

      const index = tabs.findIndex(t => t.id === viewTab)
      tabs[index].current = true

      setTabs(tabs)
      setActive(tabs[index])
    },
    [clientId, viewTab]
  )

  const onTabClick = useCallback(
    (selected: Tab) => {
      if (!clientId) return

      selected.current = true

      setActive(selected)

      replace(`/clients/${clientId}/view/${selected.id}`)
    },
    [clientId, replace]
  )

  return (
    <>
      <div>
        <div className="hidden sm:block">
          <div>
            <nav className="flex flex-row border-b border-gray-200" aria-label="Tabs">
              { tabs?.map((tab) => (
                <button
                  key={tab.title}
                  className={classNames(
                    tab.current
                      ? 'border-emerald-500 text-emerald-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap justify-between w-1/5 py-3 px-1 border-b-2 font-medium text-sm bg-white'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                  onClick={() => onTabClick(tab)}
                >
                  <div className="flex flex-row justify-center items-center space-x-1">
                    <div className="">{tab.title}</div>
                  </div>
                </button>
                ))
              }
            </nav>
          </div>
        </div>
      </div>

      { active && active.component }
    </>
  )
}
