/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
export enum Currency {
  brl = 'brl',
  usd = 'usd',
  uyi = 'uyi',
  eur = 'eur',
  ars = 'ars'
}

export enum CouponType {
  connectivity = 'connectivity',
  hardware = 'hardware',
  software = 'software'
}

export enum CouponMode {
  proportional = 'proportional',
  fixed = 'fixed'
}

export enum CouponDurationMode {
  forever = 'forever',
  once = 'once',
  repeating = 'repeating'
}

export enum Interval {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year'
}

export interface CouponMeta {
  [key: string]: any
}

export type CouponForm = Partial<Omit<Coupon, 'id' | 'created_at' | 'updated_at'>>

export interface Coupon {
  id: number
  name: string
  type: CouponType
  country_id: number | string
  currency: Currency
  duration_mode: CouponDurationMode
  duration: number | string
  interval: Interval
  created_at: Date
  updated_at: Date
  deactivated_at?: Date
  expires_at?: Date | null
  redeems_remaining?: number | string
  mode: CouponMode
  amount: number | string
  meta?: CouponMeta
  token: string
}
