import { PlanMeta } from "models"
import { translations } from "../translations"

export function extractMetaNames(meta: PlanMeta | undefined): string {
  if (!meta)
    return "-"

  if (meta.restriction.device_types?.name) {
    return translations.subtype[meta.restriction.device_types.name] as string
  } else {
    return translations.subtype[meta.restriction.chips.service_provider] as string
  }
}