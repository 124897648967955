const colorMap: Record<string, Record<string, string>> = {
  order_status: {
    pending: 'bg-gray-200 text-gray-800',
    approved: 'bg-cyan-100 text-cyan-800',
    in_line: 'bg-sky-100 text-sky-800',
    in_progress: 'bg-sky-100 text-sky-800',
    updating_fiscal_data: 'bg-sky-100 text-sky-800',
    ready_for_pick_up: 'bg-indigo-100 text-indigo-800',
    shipping: 'bg-indigo-100 text-indigo-800',
    delivered: 'bg-green-100 text-green-800',
    canceled: 'bg-rose-100 text-rose-800'
  },
  item_status: {
    ordered: 'bg-green-100 text-green-800',
    returned: 'bg-gray-200 text-gray-800',
    canceled: 'bg-red-100 text-red-800',
    repaired: 'bg-amber-100 text-amber-800',
    ownership_changed: 'bg-blue-100 text-blue-800'
  }
}

type Props = {
  label: string
  type: 'order_status' | 'item_status'
  value: string | undefined
}

export function Label ({ label, type, value }: Props) {
  const color = value ? colorMap[type][value] : 'bg-gray-200 text-gray-800'

  return (
    <span
      className={`
        px-3 inline-flex items-center text-xs leading-5
        font-semibold rounded-full ${color}
      `}
    >
      { label }
    </span>
  )
}
