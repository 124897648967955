/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { classNames } from 'utils'

type Tab = {
  name: string
  op: string
  current: boolean
}

const tabOptions = [
  { name: 'Todos', op: 'all', current: true },
  { name: 'Disponíveis', op: 'is', current: false },
  { name: 'Em uso', op: 'isn', current: false }
]

interface Props {
  onChange: (value: string) => any
}

export function Tabs({ onChange }: Props) {
  const [tabs, setTabs] = useState<Tab[]>(tabOptions)

  const onSelect = useCallback((tab: Tab) => {
    const beforeIdx = tabs.findIndex((t) => t.current)
    const selectIdx = tabs.findIndex((t) => t.op === tab.op)

    tabs[beforeIdx].current = false
    tabs[selectIdx].current = true

    setTabs([...tabs])

    onChange(tabs[selectIdx].op)
  }, [onChange, tabs])

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-emerald-500 focus:border-emerald-500 border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab.current)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <div
              key={tab.name}
              onClick={() => onSelect(tab)}
              className={classNames(
                tab.current ? 'bg-gray-50 text-gray-900' : 'bg-white text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? 'bg-emerald-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </div>
          ))}
        </nav>
      </div>
    </div>
  )
}
