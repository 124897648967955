import { useEffect } from "react"
import { useViewAccessPlan } from "../hooks/useViewAccessPlan"
import { PageHeader } from "components/PageHeader"
import { LoadingCircle } from "components/Loading"
import { Link } from "react-router-dom"
import { ProtectedSection } from "components/ProtectedSection"
import { UsageType, Currency, Role } from "models"
import { translations } from "../translations"
import { amount, currency } from "utils"
import { TierTable } from "../components/Tiers"

export function View() {
  const { isLoadingAccessPlan, accessPlan, fetchAccessPlan } =
    useViewAccessPlan()

  useEffect(() => {
    if (!accessPlan) {
      fetchAccessPlan()
    }
  }, [accessPlan, fetchAccessPlan])

  return (
    <>
      <PageHeader title="Acesso" action="Visão geral" />

      {isLoadingAccessPlan ? (
        <LoadingCircle />
      ) : (
        <div className="flex flex-row space-x-8 animate-fade-in-down">
          <div className="w-1/2">
            <div className="">
              <div className="w-full">
                <div className="flex justify-between">
                  <h3 className="ml-4 text-xl leading-6 font-medium text-gray-900">
                    {accessPlan?.name}
                  </h3>
                  <div>
                    {accessPlan?.active ? (
                      <span className="px-2 inline-flex text-base leading-5 font-semibold rounded-full bg-emerald-100 text-emerald-800">
                        Ativo
                      </span>
                    ) : (
                      <span className="px-2 inline-flex text-base leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Não ativo
                      </span>
                    )}
                  </div>
                </div>
                <div className="mt-5 border-t border-gray-200 animate-fade-in-down">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Tipo
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        { accessPlan?.usage_type === 'tiered' 
                          ? `${translations.type[accessPlan.usage_type as string]}: ${translations.tier_mode[accessPlan.tier_mode as string]}`
                          : translations.type[accessPlan?.usage_type as string]
                        }
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Descrição
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {accessPlan?.description ?? "-"}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Valor
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {accessPlan?.usage_type === UsageType.licensed
                          ? `${currency(accessPlan.currency)} 
                        ${amount(accessPlan.amount)}`
                          : "-"}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Valor mínimo da fatura
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {accessPlan?.minimum_invoice_amount &&
                        accessPlan.minimum_invoice_amount !== 0
                          ? `${currency(accessPlan.currency)} ${amount(
                              accessPlan.minimum_invoice_amount
                            )}`
                          : "-"}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Multa
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {accessPlan?.fine_mode && accessPlan.fine_amount
                          ? accessPlan.fine_mode === "fixed"
                            ? `${currency(accessPlan?.currency)} ${amount(
                                accessPlan?.fine_amount
                              )}`
                            : `${accessPlan?.fine_amount} %`
                          : "-"}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Duração
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {`${accessPlan?.duration} meses`}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Cobrança
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div className="text-xs text-left font-semibold text-gray-900">
                          {
                            translations.billing_type[
                              accessPlan?.billing_type as string
                            ]
                          }
                        </div>
                        <div className="text-xs text-left font-semibold text-gray-900">
                          {
                            translations.interval[
                              accessPlan?.interval as string
                            ]
                          }
                        </div>
                        <div className="text-xs text-left text-gray-500">
                          {accessPlan?.interval_count}
                        </div>
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Renovável
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {accessPlan?.renewable ? "Sim" : "Não"}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Restrito
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {accessPlan?.restricted ? "Sim" : "Não"}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Pro rata
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {accessPlan?.prorata ? "Sim" : "Não"}
                      </dd>
                    </div>
                  </dl>
                </div>
                {!accessPlan?.active && (
                  <ProtectedSection
                    roles={[Role.MANAGER]}
                    id={accessPlan?.id?.toString()}
                  >
                    <div className="mt-6 flex justify-center space-x-3 md:mt-0 md:ml-4">
                      <Link to={`/access-plans/${accessPlan?.id}`}>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                          Editar
                        </button>
                      </Link>
                    </div>
                  </ProtectedSection>
                )}
              </div>
            </div>
          </div>
          {accessPlan?.tiers && accessPlan.tier_mode && (
            <div className="w-1/3 relative">
              <div className="sticky top-0">
                {accessPlan.tiers.length > 0 && (
                  <div className="pt-10">
                    <TierTable
                      currency={accessPlan.currency ?? Currency.brl}
                      tiers={accessPlan.tiers}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
