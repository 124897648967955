import { useHistory } from 'react-router-dom'
import { PencilIcon } from '@heroicons/react/outline'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { useCallback, useEffect, useMemo, useState } from 'react'

import config from 'config'
import { Dot } from 'components/Dot'
import { Info } from './components/Info'
import { Label } from 'components/Label'
import { date } from 'utils/presentation'
import { useURLParams } from 'hooks/useURLParams'
import { LoadingCircle } from 'components/Loading'
import { Pagination } from 'components/Pagination'
import { SearchBar } from 'components/Search'
import { translations } from 'modules/Clients/translations'
import { UpdateDeviceStatus } from './components/UpdateDevice'
import { useViewClient } from 'modules/Clients/hooks/useViewClient'
import { ProtectedSection } from 'components/ProtectedSection'
import { Role } from 'models'
import { QueryOptions } from 'interfaces/queryOptions'
import { SimpleCustomSelect } from 'components/FormInputs'

const statusFilters = [
  { value: '', label: 'Selecione um estado' },
  { value: 'ordered', label: 'Pedido' },
  { value: 'canceled', label: 'Cancelado' },
  { value: 'returned', label: 'Retornado' },
  { value: 'ownership_changed', label: 'Vendido' }
]

export function DeviceTab () {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(
    () => {
      const params: QueryOptions = {
        search: '',
        filters: []
      }

      const search = query.get('search')
      const filters = query.get('q')

      if (search) params.search = search

      if (filters) {
        params.filters = JSON.parse(atob(filters))
      }

      return params
    },
    [query]
  )

  const {
    client, fetchClient,
    isLoadingDevices,
    devices, listDevices
  } = useViewClient()

  const [index, setIndex] = useState<number>()
  const [isOpen, setIsOpen] = useState(false)
  const [isInfoOpen, setIsInfoOpen] = useState(false)

  const maxItemsPerPage = 100
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [lastPage, setLastPage] = useState(1)
  const [search, setSearch] = useState(queryParams.search)
  const [filter, setFilter] = useState(queryParams.filters?.length ? queryParams.filters[0].value : '')

  const [options, setOptions] = useState<QueryOptions>({
    page: 1,
    count: true,
    limit: maxItemsPerPage,
    search: queryParams.search,
    filters: queryParams.filters
  })

  useEffect(
    () => {
      if (!client) {
        fetchClient()
      }
    },
    [client, fetchClient]
  )

  useEffect(
    () => {
      if (!client) return

      listDevices(options)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, listDevices, search, page, filter]
  )

  useEffect(
    () => {
      if (!devices) return

      setTotal(devices.count)
    },
    [devices]
  )

  useEffect(
    () => {
      const newLastPage = Math.ceil(total / maxItemsPerPage)
      setLastPage(newLastPage)
    },
    [maxItemsPerPage, total]
  )

  useEffect(
    () => {
      if (options.filters && options.filters.length > 0) {
        setFilter(options.filters[0].value)
      } else {
        setFilter('')
      }
      setPage(options.page ?? 1)
      setSearch(options.search ?? '')
    },
    [options.filters, options.page, options.search]
  )

  const onFilter = useCallback(
    (value: string) => {
      const filters = []

      if (value === '') {
        query.delete('q')
      } else {
        filters.push({ key: 'item_status', op: 'eq', value: value })
        query.set('q', btoa(JSON.stringify(filters)))
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setOptions({ ...options, filters })
    },
    [options, query, replace]
  )

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setOptions({ ...options, page: newPage })
    },
    [lastPage, options]
  )

  const onSearch = useCallback(
    (term: string | undefined) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      term === ''
        ? query.delete('search')
        : query.set('search', term)

      const isSettingSearch = options.search === '' && term !== ''
      const isCleaningSearch = options.search !== '' && term === ''
      if (isSettingSearch || isCleaningSearch) {
        options.page = 1
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      options.search = term

      setOptions({ ...options })
    },
    [options, query, replace]
  )

  return (
    <>
      <div>
        <div className="py-6 flex flex-row justify-between animate-fade-in-down">
          <SearchBar value={search ?? ''} onChange={onSearch} />

          <div className="w-1/5">
            <SimpleCustomSelect
              label=""
              options={statusFilters}
              value={filter}
              onChange={onFilter}
            />
          </div>
        </div>
          {
            isLoadingDevices
            ? <div className="py-8"><LoadingCircle /></div>
            : (
              <div>
                <div className="flex flex-col animate-fade-in-down">
                  <div className="">
                    <div className="py-2 inline-block min-w-full">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                  scope="col"
                                  className="px-3 py-3 text-left text-sm font-light text-gray-500 uppercase"
                                >
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                Código
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                Pedido
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                Rastreador
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                Modelo
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                Estado
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                Nota fiscal de saída
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3 text-left text-sm font-light text-gray-500 uppercase"
                              >
                                <button
                                  type="button"
                                  className=" text-blue-700 outline-none"
                                  onClick={() => setIsInfoOpen(true) }
                                >
                                  <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-left bg-white divide-y divide-gray-200">
                            {devices?.data.map((device, idx) => (
                              <tr key={idx} className={idx % 2 ? 'bg-gray-50' : 'bg-white'}>
                                <td className="pl-4 py-3 text-left text-sm whitespace-nowrap">
                                  <Dot color={device.attributes.activated ? 'emerald' : 'red'} />
                                </td>
                                <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                  <div className="flex items-center">
                                    <div className="flex-1 truncate">
                                      <div className="text-xs text-left font-semibold text-gray-900 hover:text-indigo-900">
                                        { device.attributes.token || '-' }
                                      </div>
                                      <div className="text-xs text-left text-gray-500">
                                        { device.attributes.batch || '-' }
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                  <Label
                                    type="order_status"
                                    label={translations['order_status'][device.attributes.order_status as string]}
                                    value={device.attributes.order_status}
                                  />
                                </td>
                                <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                  <div className="flex items-center">
                                      <div className="flex-1 truncate">
                                        <div className="text-xs text-left font-semibold text-gray-900">
                                          { device.attributes.imei || '-' }
                                        </div>
                                        <div className="text-xs text-left text-gray-500">
                                          { device.attributes.name || '-' }
                                        </div>
                                      </div>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                  <div className="flex items-center">
                                      <div className="flex-1 truncate">
                                        <div className="text-xs text-left font-semibold text-gray-900">
                                          { device.attributes.brand || '-' }
                                        </div>
                                        <div className="text-xs text-left text-gray-500">
                                          { device.attributes.model || '-' }
                                        </div>
                                      </div>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                  <Label
                                    type="item_status"
                                    label={translations['item_status'][device.attributes.item_status as string]}
                                    value={device.attributes.item_status}
                                  />
                                </td>
                                <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                                  <div className="text-xs text-left font-semibold text-gray-900">
                                    { `${translations['write_off_motive'][device.attributes.write_off_invoice_motive as string] ?? '-'} - ${device.attributes.write_off_invoice ?? '-'}` }
                                  </div>
                                  <div className="text-xs text-left text-gray-500">
                                    { device.attributes.write_off_date ? date(device.attributes?.write_off_date) : '-' }
                                  </div>
                                </td>
                                <td className="p-3 whitespace-nowrap text-sm font-medium">
                                  <ProtectedSection roles={[Role.MANAGER, Role.FINANCE]}>
                                  {
                                    ['ready_for_pick_up', 'shipping', 'delivered'].includes(device.attributes.order_status ?? 'pending') &&
                                    device.attributes.item_status === 'ordered' && (
                                      <button
                                        type="button"
                                        className="relative inline-flex items-center px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                        onClick={ () => { setIndex(idx); setIsOpen(true) } }
                                      >
                                        <PencilIcon className="h-4 w-4" aria-hidden="true" />
                                      </button>
                                    )
                                  }
                                  </ProtectedSection>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <Pagination
                  pageSize={maxItemsPerPage}
                  currentPage={page}
                  itemsOnPage={devices.data.length}
                  totalItems={total}
                  onPageChange={onPageChange}
                />
              </div>
            )
          }
      </div>

      <UpdateDeviceStatus index={index} isOpen={isOpen} setOpen={setIsOpen} />
      <Info isOpen={isInfoOpen} setIsOpen={setIsInfoOpen} />
    </>
  )
}