import jwtDecode, { JwtPayload } from 'jwt-decode'
import { toast } from 'react-toastify'
import { Route, Redirect } from "react-router"
import { useContext, ReactNode, useEffect, useState } from 'react'

import { Role } from 'models'
import { AuthContext } from 'modules/Auth/context'

interface PrivateRouteProps {
  children: ReactNode
  roles?: Role[]
  [key: string]: any
}

function validateToken(token: string | undefined): boolean {
  if (!token) return false

  const jwtToken = jwtDecode(token) as JwtPayload
  const now = Math.floor(Date.now() / 1000)

  return now < (jwtToken.exp as number)
}

export function PrivateRoute({ children, roles, ...rest }: PrivateRouteProps) {
  const { user, token, logout } = useContext(AuthContext)

  const [renderElement, setRenderElement] = useState<ReactNode>(null as any)
  const [isTokenValid, setIsTokenValid] = useState(true)
  const [hasPermission, setHasPermission] = useState(true)

  useEffect(() => {
    if (token && user) {
      setRenderElement(children)
      setIsTokenValid(validateToken(token.raw))
      const rolesWithAccess = roles ?? Object.keys(Role)
      rolesWithAccess.push(Role.ROOT)
      setHasPermission(rolesWithAccess.includes(user.relationships?.role.attributes.name))
    }
  }, [user, children, token, roles])

  useEffect(() => {
    if (!isTokenValid) {
      logout()
      toast.error('Sessão expirada! Faça o login novamente para renovar sua sessão')
    }
  }, [isTokenValid, logout])

  useEffect(() => {
    if (!hasPermission) {
      toast.error('Usuário sem permissão para acesso!')
    }
  }, [hasPermission])

  if (!isTokenValid) {
    return <Redirect to={{ pathname: '/login' }} />
  }

  if (!hasPermission) {
    return <Redirect to={{ pathname: '/', state: { from: rest.location } }} />
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token
          ? renderElement
          : <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
      }
    />
  )
}
