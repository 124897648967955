import { toast } from 'react-toastify'
import { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import {
  InputWithValidationError,
  RadioListWithDescription,
  ResizibleTextArea,
  CheckboxWithDescription,
  SimpleCustomSelect,
  InputWithAddon,
  InlineList
} from 'components/FormInputs'
import { FormSection } from 'components/Form'
import { DestroyButton } from '../components/Destroy'
import { PageHeader } from 'components/PageHeader'
import { LoadingCircle } from 'components/Loading'
import { ProtectedSection } from 'components/ProtectedSection'
import { billingTypes, currencies, fineModes, intervals, natures, planTypes, serviceProviders, deviceTypes, tierModes, usageTypes } from 'components/FormInputs/options'

import { Errors } from 'types'
import { Currency, Plan, PlanMeta, PlanType, Role, Tier } from 'models'
import { check } from 'utils/error'
import { validate } from '../validation'
import { amount, cleanPercentageAmount, currency, formatCurrency } from 'utils'
import { edit, show } from '../services/api'
import { cleanAmount } from 'utils'
import { CreateTier, TierTable } from 'modules/AccessPlans/components/Tiers'

export function Edit () {
  const { planId } = useParams() as unknown as { planId: number }

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [plan, setPlan] = useState<Record<string, any>>()
  
  const [planMeta, setPlanMeta] = useState(deviceTypes[0].value)
  const [planMetaOptions, setPlanMetaOptions] = useState(deviceTypes)

  const [tiers, setTiers] = useState<Tier[]>([])
  const [errors, setErrors] = useState<Errors>({})

  useEffect(
    () => {
      const fetchPlan = async () => {
        try {
          if (!planId) return
          setIsLoading(true)

          const data = await show(planId)
          setPlan({ ...data, amount: amount(data.amount), minimum_invoice_amount: amount(data.minimum_invoice_amount), fine_amount: data.fine_mode === 'fixed' ? amount(data.fine_amount) : data.fine_amount, meta: null })

          if (data.meta?.restriction) {
            if (data.meta.restriction.device_types?.name) {
              setPlanMeta(data.meta.restriction.device_types.name)
              setPlanMetaOptions(deviceTypes)
            } else {
              setPlanMeta(data.meta.restriction.chips.service_provider)
              setPlanMetaOptions(serviceProviders)
            }
          }

          if (data.tiers) {
            setTiers(data.tiers)
          }

          setIsLoading(false)
        } catch (err: any) {
          setIsLoading(false)
          toast.error(err.suggestedMessage ?? 'Falha ao recuperar os dados do plano')
        }
      }
      fetchPlan()
    },
    [planId]
  )

  const onSave = useCallback(
    async () => {
      if (!plan) return

      plan.tiers = tiers

      const transformAttributes = (attributes: Record<string, any>): Record<string, any> => {
        const {
          minimum_invoice_amount,
          fine_mode,
          fine_amount,
          tier_mode,
          tiers
        } = attributes

        return {
          minimum_invoice_amount: minimum_invoice_amount === '' ? null : cleanAmount(minimum_invoice_amount),
          fine_mode: fine_amount === '' ? null : fine_mode,
          fine_amount: fine_amount === '' ? null : fine_mode === null ? null :  fine_mode === 'fixed' ? cleanAmount(fine_amount) : cleanPercentageAmount(fine_amount),
          tier_mode: tiers === null ? null : tiers?.length === 0 ? null : tier_mode,
          tiers: tier_mode === null || (tiers && tiers.length === 0) ? null : tiers
        }
      }

      if (check(errors)) return

      const otherAttributes = transformAttributes(plan)

      try {
        plan.country_id = undefined
        await edit(planId, {...plan, amount: cleanAmount(plan.amount), ...otherAttributes }, planMeta as string)
        toast.success('Plano editado com sucesso')
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Houve um erro ao tentar salvar as alterações')
      }
    },
    [errors, planId, plan]
  )

  const onAttributeChange = useCallback(
    (attr: keyof Partial<Plan> | keyof Partial<PlanMeta>, value: any): void => {
      if (!plan) return

      const error = validate(attr as any, value)

      if (attr === 'type') {
        if (value === PlanType.hardware) {
          setPlanMeta(deviceTypes[0].value)
          setPlanMetaOptions(deviceTypes)
        } else {
          setPlanMeta(serviceProviders[0].value)
          setPlanMetaOptions(serviceProviders)
        }
      }

      if (attr === 'fine_amount' && plan.fine_mode === 'fixed') {
        value = formatCurrency(value)
      }
      
      if (attr === 'meta') {
        setPlanMeta(value)
      } else {
        setPlan({ ...plan, [attr]: value })
      }

      setErrors({ ...errors, [attr]: error })
    },
    [errors, plan]
  )

  return (
    <>
      <PageHeader title="Plano" action="Edição" />

      {
        isLoading
        ? <LoadingCircle />
        : (
          <>
            <div className="flex flex-row space-x-8">
              <div className="w-3/5 px-4 animate-fade-in-down">
                <form onSubmit={(e) => { e.preventDefault(); onSave() }}>
                  <FormSection title='Edição plano'>
                    <InlineList
                      label='Tipo de plano'
                      description=""
                      options={planTypes}
                      value={plan?.type}
                      onChange={(type) => onAttributeChange('type', type)}
                    />

                    <InputWithValidationError
                      label='Nome'
                      name='name'
                      value={plan?.name}
                      onChange={(name) => onAttributeChange('name', name)}
                      error={errors.name}
                    />

                    <ResizibleTextArea
                      label='Descrição'
                      name='description'
                      value={plan?.description}
                      onChange={(description) => onAttributeChange('description', description)}
                    />
                  </FormSection>

                  <FormSection title='Valores e Períodos'>
                  <div className="flex flex-row space-x-8">
                      <div className="w-1/3">
                        <SimpleCustomSelect
                          label="Moeda"
                          options={currencies}
                          value={plan?.currency}
                          onChange={(currency) => onAttributeChange('currency', currency)}
                        />
                      </div>

                      <div className="w-1/3">
                        <InputWithAddon
                          label="Valor"
                          name="amount"
                          type="text"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          value={plan?.amount}
                          frontAddon={currency(plan?.currency)}
                          onChange={(value) => onAttributeChange('amount', value)}
                          error={errors.amount}
                        />
                      </div>
                    </div>

                    <div className="flex flex-row space-x-8">
                      <div className="w-1/3">
                        <InputWithValidationError
                          label="Duração"
                          optional="meses"
                          name="duration"
                          value={plan?.duration}
                          onChange={(duration) => onAttributeChange('duration', duration)}
                          error={errors.duration}
                        />
                      </div>

                      <div className="w-1/3">
                        <SimpleCustomSelect
                          label='Cobrança'
                          options={intervals}
                          value={plan?.interval}
                          onChange={(interval) => onAttributeChange('interval', interval)}
                        />
                      </div>

                      <div className="w-1/3">
                        <InputWithValidationError
                          label='Intervalo'
                          name='interval_count'
                          value={plan?.interval_count}
                          onChange={(intervalCount) => onAttributeChange('interval_count', intervalCount)}
                          error={errors.interval_count}
                        />
                      </div>
                    </div>

                    <RadioListWithDescription
                      label='Modo de Fatura'
                      options={billingTypes}
                      value={plan?.billing_type}
                      onChange={(billingType) => onAttributeChange('billing_type', billingType)}
                    />

                    <div className="flex flex-row space-x-8">
                      <div className="w-1/3">
                        <SimpleCustomSelect
                          label="Tipo de multa"
                          options={fineModes}
                          value={plan?.fine_mode}
                          onChange={(fineModes) => onAttributeChange('fine_mode', fineModes)}
                        />
                      </div>

                      <div className="w-1/3">
                        <InputWithAddon
                          label="Valor da multa (por unidade)"
                          name="fine_amount"
                          type="text"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          value={plan?.fine_amount}
                          frontAddon={plan?.fine_mode === 'fixed' ? currency(plan?.currency) : '%'}
                          onChange={(value) => onAttributeChange('fine_amount', value)}
                          error={errors.amount}
                        />
                      </div>
                    </div>
                
                    <InlineList
                      label="Modo de faixas"
                      description="Definem como será feita a cobrança em cada faixa."
                      value={plan?.tier_mode}
                      options={tierModes}
                      onChange={(tierMode) => onAttributeChange('tier_mode', tierMode)}
                    />

                    <CreateTier
                      selectedCurrency={plan?.currency}
                      tiers={tiers}
                      setTiers={setTiers}
                      errors={errors}
                      setErrors={setErrors}
                    />

                  </FormSection>

                  <FormSection title='Restrições'>
                    <fieldset className="space-y-5">
                      <legend className="text-left">Extensão de prazos</legend>
                      <CheckboxWithDescription
                        label='Renovável'
                        name='renewable'
                        checked={plan?.renewable ?? false}
                        onChange={(renewable) => onAttributeChange('renewable', renewable)}
                        description='Se o plano permite renovação este campo deverá ser ativado, lembrando que comodatos de rastreadores não devem ter essa condição como ativada'
                      />
                    </fieldset>

                    <fieldset className="space-y-5">
                      <legend className="text-left">Aplicação de descontos</legend>
                      <CheckboxWithDescription
                        label='Restrito'
                        name='restricted'
                        checked={plan?.restricted ?? false}
                        onChange={(restricted) => onAttributeChange('restricted', restricted)}
                        description='Planos restritos não podem ser aplicados nenhum tipo de desconto'
                      />
                    </fieldset>

                    <fieldset className="space-y-5">
                      <legend className="text-left">Definição de garantias</legend>
                      <CheckboxWithDescription
                        label='Devedor solidário'
                        name='guarantors'
                        checked={plan?.guarantors  ?? false}
                        onChange={(guarantors) => onAttributeChange('guarantors', guarantors)}
                        description='Planos de hardware em sua maioria necessitam de uma segurança financeira para serem vendidos'
                      />
                    </fieldset>

                    <fieldset className="space-y-5">
                      <legend className="text-left">Definições Logísticas</legend>
                      <CheckboxWithDescription
                        label='Necessita de suporte logístico'
                        name='logistics'
                        checked={plan?.logistics  ?? false}
                        onChange={(logistics) => onAttributeChange('logistics', logistics)}
                        description='Escolha esta opção se o plano necessita de etapas de construção internas'
                      />
                    </fieldset>

                    <fieldset className="space-y-5">
                      <legend className="text-left">Dados fiscais</legend>
                      <CheckboxWithDescription
                        label='Necessita de dados fiscais'
                        name='fiscal_data'
                        checked={plan?.fiscal_data ?? false}
                        onChange={(fiscalData) => onAttributeChange('fiscal_data', fiscalData)}
                        description='Escolha esta opção se o plano necessita de etapas de formalizações fiscais'
                      />
                    </fieldset>
                  </FormSection>

                  <FormSection title='Estruturas de uso'>
                    <RadioListWithDescription
                      label='Natureza'
                      options={natures}
                      value={plan?.nature}
                      onChange={(nature) => onAttributeChange('nature', nature)}
                    />

                    <SimpleCustomSelect
                      label='Restrição'
                      options={planMetaOptions}
                      value={planMeta}
                      onChange={(meta) => onAttributeChange('meta', meta)}
                    />

                    <RadioListWithDescription
                      label='Tipo do plano'
                      options={usageTypes}
                      value={plan?.usage_type}
                      onChange={(usageType) => onAttributeChange('usage_type', usageType)}
                    />
                  </FormSection>

                  <div className="mt-12 flex justify-end space-x-4">
                    <ProtectedSection roles={[Role.MANAGER, Role.FINANCE]}>
                      <DestroyButton id={planId} />
                    </ProtectedSection>

                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      onClick={() => history.goBack()}
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none"
                    >
                      Salvar
                    </button>
                  </div>
                </form>
              </div>

              <div className="w-1/3 relative">
                <div className="sticky top-0">
                  {
                    tiers.length > 0 && (
                      <div className="pt-10">
                        <TierTable
                          currency={plan?.currency ?? Currency.brl}
                          tiers={tiers}
                          setTiers={setTiers}
                        />
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </>
          )
      }
    </>
  )
}
