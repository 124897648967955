import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { OfficeBuildingIcon } from '@heroicons/react/outline'

import { document } from 'utils/presentation'
import { ViewTabs } from '../components/Tabs/View'
import { PageHeader } from 'components/PageHeader'
import { useViewClient } from '../hooks/useViewClient'
import { ClientStatus } from 'models/clientStatusHistory'
import { statuses } from '../helpers/constants'
import { LoadingCircle } from 'components/Loading'

type Selected = { value: ClientStatus, label: string }

function getLabel (selected: Selected) {
  const color = {
    'pending': 'stone',
    'active': 'emerald',
    'canceled': 'red',
    'overdue': 'yellow',
    'not_renewed': 'indigo',
    'restricted': 'orange'
  }

  return (
    <div className={`w-full px-3 py-0.5 inline-flex items-center text-sm leading-5 font-semibold rounded-full bg-${color[selected.value]}-100 text-${color[selected.value]}-800`}>
      <svg className={`-ml-0.5 mr-1.5 h-2 w-2 text-${color[selected.value]}-800`} fill="currentColor" viewBox="0 0 8 8">
        <circle cx={4} cy={4} r={3} />
      </svg>
      <span className="block truncate">{selected.label}</span>
    </div>
  )
}

export function View () {
  const {
    isLoadingClient,
    client,
    fetchClient
  } = useViewClient()

  useEffect(
    () => {
      if (!client) {
        fetchClient()
      }
    },
    [client, fetchClient]
  )

  return (
    <>
      <PageHeader title="Cliente" action="Visão Geral" />

      {
        isLoadingClient
        ? <LoadingCircle />
        : (
          <>
            <div className="px-4">
              <div className="">
                <div className="py-4 md:flex md:items-center md:justify-between">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                            {client?.attributes.account_name}
                          </h1>
                        </div>
                        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                          <dt className="sr-only">Document</dt>
                          <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                            <OfficeBuildingIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            {document(client?.attributes.document, client?.attributes.document_type)}
                          </dd>
                          <dt className="sr-only">Status</dt>
                          <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                            {client?.relationships?.statuses && client.relationships.statuses.length > 0 ? (
                              getLabel(
                                statuses.find(
                                  (option) => option.value === client.relationships?.statuses[0].attributes.status
                                ) ?? statuses[0]
                              )
                            ) : (
                              "N/A"
                            )}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <Link to={`/clients/${client?.id}/edit/general`}>
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                      >
                        Editar
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4">
              <ViewTabs />
            </div>
          </>
        )
      }
    </>
  )
}
