import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useCallback, useEffect, useState } from 'react'

import { StringOrNull } from 'types'

const LocalStorage = window.localStorage

export type Token = null | {
  raw: string,
  data: Record<string, any> | null
}

function isTokenValid (token: string|null): boolean {
  if (!token) return false

  const now = Math.floor(Date.now() / 1000)

  const jwtToken = jwtDecode(token) as JwtPayload

  return now < (jwtToken.exp as number)
}

type TokenHook = [
  Token,
  ({ token }: { token: StringOrNull }) => void
]

export function useToken (): TokenHook {
  const [token, setToken] = useState<Token>(null)

  const updateToken = useCallback(
    ({ token: rawToken }: { token: StringOrNull }): void => {
      if (rawToken) {
        setToken({
          raw: rawToken,
          data: jwtDecode(rawToken) as JwtPayload | null
        })
      } else {
        setToken(null)
      }
    }, []
  )

  useEffect(() => {
    const token = LocalStorage.getItem('@Softruck:token')

    if (isTokenValid(token)) {
      updateToken({ token })
    }
  }, [updateToken])

  useEffect(() => {
    if (token) {
      LocalStorage.setItem('@Softruck:token', token.raw)
    } else {
      LocalStorage.removeItem('@Softruck:token')
    }
  }, [token])

  return [token, updateToken]
}
