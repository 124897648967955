import { Order } from 'models'
import {
  list as baseList,
  show as baseShow,
} from 'services/billing'
import { ListResult, QueryOptions } from 'interfaces/queryOptions'

export async function list (options: QueryOptions = {}): Promise<ListResult<Order>> {
  const response = await baseList<Order>('/subscriptions', options)
  return response
}

export async function show (id: string, options?: Pick<QueryOptions, 'attributes'|'includes'>): Promise<Partial<Order>> {
  const response = await baseShow<Order>('/subscriptions', id, options)

  return {
    ...response.attributes,
    id: response.id,
    uuid: response.uuid,
    relationships: response.relationships
  }
}
