import { ReactNode, createContext, useCallback, useState } from "react"
import { UseViewAccessPlanProps } from "../hooks/useViewAccessPlan"
import { useParams } from "react-router-dom"
import { Plan } from "models"
import { toast } from "react-toastify"
import { show } from "../services/read"


type Props = {
  children: ReactNode
}

export const AccessPlanViewContext = createContext<UseViewAccessPlanProps>(
  {} as UseViewAccessPlanProps
)

export function AccessPlanViewProvider({ children }: Props) {
  const { accessId } = useParams() as unknown as { accessId: string }

  const [isLoadingAccessPlan, setIsLoadingAccessPlan] = useState(true)
  const [accessPlan, setAccessPlan] = useState<Partial<Plan>>()

  const fetchAccessPlan = useCallback(
    async () => {
      if (!accessId) return

      setIsLoadingAccessPlan(true)
      try {
        const options = { includes: { country: ['*'] } }

        const data = await show(accessId, options)

        setAccessPlan(data)

        setIsLoadingAccessPlan(false)
      } catch (error: any) {
        setIsLoadingAccessPlan(false)
        toast.error(error.suggestedMessage ?? 'Falha ao recuperar dados do acesso')
      }
    },
    [accessId]
  )

  return (
    <AccessPlanViewContext.Provider
      value={{
        isLoadingAccessPlan,
        accessPlan,
        setAccessPlan,
        fetchAccessPlan
      }}
    >
      { children }
    </AccessPlanViewContext.Provider>
  )
}