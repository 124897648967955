import { Errors } from 'types'

export function check (errors: Errors): boolean {
  for (const key in errors) {
    if (errors[key]) return true
  }

  return false
}

function isFilled (value: string | null | undefined) {
  if (!value) return false

  return !(!value.trim().length)
}

export function checkIsFilled (obj: Record<string, any>, requiredKeys: string[]) {
  const filled: Record<string, boolean> = {}

  for (const key of requiredKeys) {
    filled[key] = obj.hasOwnProperty(key) && isFilled(obj[key])
  }
  
  return filled
}

export function checkIsValid (obj: Record<string, boolean>) {
  return Object.values(obj).every((key) => key === true)
}