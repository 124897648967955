type Color = 'emerald' | 'red'

const colorMap = {
  emerald: {
    light: 'bg-emerald-100',
    dark: 'bg-emerald-400'
  },
  red: {
    light: 'bg-red-100',
    dark: 'bg-red-400'
  }
}

export function Dot ({ color }: { color: Color }) {
  return (
    <span className={`h-4 w-4 rounded-full inline-flex items-center justify-center ${colorMap[color].light}`} aria-hidden="true">
      <span className={`h-2 w-2 rounded-full ${colorMap[color].dark}`} />
    </span>
  )
}