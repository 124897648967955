import React from "react"

interface DayButtonsProps {
  allowedDays: string
  onToggleDay: (index: number, event: React.MouseEvent<HTMLButtonElement>) => any
}

export function DayButtons ({allowedDays, onToggleDay}: DayButtonsProps) {
  const days = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']

  const handleButtonClick = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
    if(event) {
      onToggleDay(index, event)
    }
  }

  return (
    <div className="flex-col space-y-2">
      <label className="block text-left text-sm font-medium text-gray-700">
        Dias de acesso
      </label>
      <div className="flex flex-row space-x-2">
        {days.map((day, index) => (
          <button
            key={index}
            className={`flex items-center justify-center w-8 h-8 rounded-md ${
              allowedDays[index] === '1' ? 'bg-emerald-500 text-white' : 'bg-white border text-gray-500'
            }`}
            onClick={(event) => handleButtonClick(index, event)}
          >
            {day}
          </button>
        ))}
      </div>
    </div>
  )
}