import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Errors } from "types";

import { validate } from "../validations/user";

import { check } from "utils/error";
import { toast } from "react-toastify";

import { createStaff, listRoles } from "../services/api";
import { Button, Close } from "components/Buttons";
import { InputWithValidationError, RadioListWithDescription, SimpleCustomSelect } from "components/FormInputs";

import { languages, roles as rolesNames } from "../helpers/constants";
import { ListResult } from "interfaces/queryOptions";
import { RoleModel } from "models";
import { cleanPayload } from "utils/requests";

export function Create () {
  const OTP_LENGTH = 12
  const OTP_CHARSET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()_-+=<>?/[]{},.:;'

  const history = useHistory()

  const [isSaving, setIsSaving] = useState(false)

  const [selectedRole, setSelectedRole] = useState("")
  const [roles, setRoles] = useState<ListResult<RoleModel>>({count: 0, data: []})

  const [user, setUser] = useState<Record<string, any>>({
    fullname: "",
    email: "",
    language: "",
    phone_number: null,
    role_id: ""
  })

  const [errors, setErrors] = useState<Errors>({})

  useEffect(() => {
    const fetchRoles = async () => {
      const options = {
        attributes: ['name']
      }
      try {
        const result = await listRoles(options)
        setRoles(result)
        const role = result.data.find((role) => role.attributes.name === "ROOT")
        setUser({...user, role_id: role?.id})
      } catch (error: any) {
        toast.error(error.suggestedMessage ?? 'Falha ao recuperar papéis')
      }
    }

    fetchRoles()
    }, []
  )

  const validateFields = (user: Record<string, any>): boolean => {
    const isFilled = {
      fullname: (user.hasOwnProperty('fullname') && user.fullname !== ''),
      email: (user.hasOwnProperty('email') && user.email !== ''),
      language: (user.hasOwnProperty('language') && user.language !== ''),
      role_id: (user.hasOwnProperty('role_id') && user.role_id !== '')
    }

    const isUserValid  = isFilled.fullname && isFilled.email && isFilled.language && isFilled.role_id
    setErrors({
      ...errors,
      fullname: (!isFilled.fullname) ? 'O campo deve ser preenchido' : null,
      email: (!isFilled.email) ? 'O campo deve ser preenchido' : null,
      language: (!isFilled.language) ? 'O campo deve ser preenchido' : null,
      role_id: (!isFilled.role_id) ? 'O campo deve ser preenchido' : null
    })

    return !isUserValid
  }

  const onAttributeChange = useCallback(
    (attr: string, value: any) => {
      const error = validate(attr as any, value)

      if(attr === 'role_id'){
        const role = roles.data.find((role) => role.attributes.name === value)
        if(role?.attributes.name){
          value = role?.id
          setSelectedRole(role?.attributes.name)
        }
      }

      setUser({ ...user, [attr]: value })
      setErrors({ ...errors, [attr]: error })
    },
    [user, errors, roles.data]
  )

  function generateOTP(length: number): string {
    let otp = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * OTP_CHARSET.length);
      otp += OTP_CHARSET[randomIndex];
    }
  
    return otp;
  }

  const onSave = async () => {
    setIsSaving(true)

    if (validateFields(user) || check(errors)) {
      setIsSaving(false)
      return
    }

    try {
      const payload = {
        attributes: {
          fullname: user.fullname,
          email: user.email,
          language: user.language,
          password: generateOTP(OTP_LENGTH),
          phone_number: user.phone_number
        },
        relationships: {
          role: {
            type: "role",
            id: user.role_id
          }
        }
      }

      await createStaff(cleanPayload(payload, true))

      toast.success('Usuário criado com sucesso')
      setIsSaving(false)

      history.push('/staff-members')
    } catch (err: any) {
      toast.error(err.suggestedMessage ?? 'Houve uma falha ao tentar criar o usuário')

      if (err.message.includes('email')) {
        setErrors({ email: 'Email inválido'})
      }

      setIsSaving(false)
    }
  }

  return (
    <>
      <div className="flex flex-row justify-between mt-10 animate-fade-in-down">
        <div className="w-1/2 px-4">
          <form onSubmit={(e) => { e.preventDefault(); onSave() }} noValidate>
            <div className="space-y-6 mb-5">
              <div className="flex flex-row justify-between">
                <div className="text-xl text-left leading-6 font-medium text-gray-900">
                  Informações de cadastro
                </div>
              </div>

              <InputWithValidationError
                label="Nome"
                name="fullname"
                value={user.fullname}
                onChange={(value) => onAttributeChange('fullname', value)}
                error={errors.fullname}
              />

              <InputWithValidationError
                label="E-mail"
                name="email"
                type="email"
                value={user.email}
                onChange={(value) => onAttributeChange('email', value)}
                error={errors.email}
              />
                
              <InputWithValidationError
                label="Telefone"
                name="phone_number"
                type="phone_number"
                value={user.phone_number}
                onChange={(value) => onAttributeChange('phone_number', value)}
                error={errors.phone}
              />

              <SimpleCustomSelect
                label="Idioma"
                options={languages}
                value={user.language}
                onChange={(value) => onAttributeChange('language', value)}
              />

              <RadioListWithDescription
                label="Papel do usuário"
                options={rolesNames}
                value={selectedRole}
                onChange={(value) => onAttributeChange('role_id', value)}
              />

            </div>

            <div className="mt-8 w-full space-x-4 flex justify-end animate-fade-in-down">
              <Close linkTo="/staff-members" />
              <Button disabled={isSaving} label="Salvar" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}