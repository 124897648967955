import { Link } from 'react-router-dom'

interface Props {
  linkTo?: string
  onClick?: () => any
}

function CloseButton ({ onClick }: Props) {
  return (
    <button
      type="button"
      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
      onClick={onClick}
    >
      Fechar
    </button>
  )
}

export function Close ({ onClick, linkTo }: Props) {
  return (
    linkTo
    ? (
        <Link to={linkTo}>
          <CloseButton onClick={onClick} />
        </Link>
      )
    : (
        <CloseButton onClick={onClick} />
      )
  )
}