interface Props {
  type: 'button' | 'submit' | 'reset' | undefined
  label?: string
  disabled?: boolean
  onClick?: () => any
}

export function Button ({ disabled, onClick, type, label }: Props) {
  return (
    <button
      disabled={disabled}
      type={type}
      className={`w-2/12 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none ${disabled ? 'bg-gray-300' : 'bg-emerald-600 hover:bg-emerald-700'}`}
      onClick={onClick}
    >
      { disabled
        ? (
          <div className="flex justify-center items-center">
            <svg className="animate-spin rounded-full h-5 w-5" viewBox="0 0 24 24" fill="none">
              <circle className="opacity-25 stroke-current text-gray-700" cx="12" cy="12" r="10" strokeWidth="4"></circle>
              <path className="opacity-75 fill-current text-gray-700" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        )
        : label ?? "Salvar"
      }
    </button>
  )
}