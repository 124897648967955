import { checkRoles, isNil, omitBy } from 'utils'
import { cleanPhone } from '../helpers/utils'

import { Enterprise } from 'models/enterprise'
import { Customization } from 'models/customization'
import { Client, ClientAddress, Role, User } from 'models'
import { ClientStatus, ClientStatusHistory } from 'models/clientStatusHistory'

import { ReadResult } from 'interfaces/queryOptions'
import { edit as baseEdit, patch } from 'services/billing'
import { edit as editEnterpriseApi } from 'services/enterprises'
import {
  edit as editUserApi,
  generatePasswordToken,
  sendWelcomeEmail
} from 'services/users'

export async function edit (id: number, attributes: Record<string, any>): Promise<ReadResult<Client>> {
  attributes.picture_id = undefined
  const data: Record<string, any> = { attributes: omitBy(attributes, isNil) }

  const response = await baseEdit<Client>('/clients', id, data)
  return response
}

export async function updateStatus (id: number, newStatus: ClientStatus): Promise<ReadResult<ClientStatusHistory> | undefined> {
  if (newStatus === 'active') {
    const result = await patch(`/clients/${id}/activate`)
    return result.data
  }

  if (newStatus === 'overdue' || newStatus === 'restricted') {
    const reason = newStatus === 'overdue' ? 'overdue' : 'restrict'
    const result = await patch(`/clients/${id}/overdue/${reason}`)
    return result.data
  }

  if (newStatus === 'canceled' || newStatus === 'not_renewed') {
    const reason = newStatus === 'canceled' ? 'cancel' : 'not_renew'
    const result = await patch(`/clients/${id}/finalize/${reason}`)
    return result.data
  }
}

export async function editAddress (id: number, payload: Record<string, any>): Promise<ReadResult<ClientAddress>> {
  const response = await baseEdit<ClientAddress>('/addresses', id, payload)
  return response
}

export async function editEnterprise (id: string, attributes: Record<string, any>): Promise<ReadResult<Enterprise>> {
  const result = await editEnterpriseApi<Enterprise>('', { attributes }, id)
  return result
}

export async function editAccounts (enterpriseId: number, attributes: Record<string, any>): Promise<ReadResult<Enterprise>> {
  const payload = {
    attributes: {
      accounts: attributes
    },
    relationships: {
      enterprise: {
        type: 'enterprise',
        id: enterpriseId
      }
    }
  }
  const result = await editEnterpriseApi<Enterprise>('/accounts', payload)
  return result
}

export async function editCustomization (enterpriseUuid: string, customizationUuid: string, imageId: number, attributes: Record<string, any>): Promise<ReadResult<Customization>> {
  attributes.created_at = undefined
  attributes.updated_at = undefined

  if (attributes.apps.ios === '') {
    delete attributes.apps.ios
  }

  if (attributes.apps.android === '') {
    delete attributes.apps.android
  }

  const payload = {
    attributes: { ...omitBy(attributes, isNil) },
    relationships: {
      picture: {
        type: 'picture',
        id: imageId
      }
    }
  }

  const url = `/${enterpriseUuid}/customizations`
  const result = await editEnterpriseApi<Customization>(url, payload, customizationUuid)
  return result
}

export async function editUser (id: string, attributes: Record<string, any>): Promise<ReadResult<User> | any> {
  const payload = {
    attributes: {
      name: attributes.name,
      email: attributes.email,
      phone1: cleanPhone(attributes.phone1)
     }
  }

  const result = await editUserApi<User>(id, payload)
  return result
}

export async function updateItemStatus (orderId: number | undefined, payload: any[]): Promise<void> {
  if (!orderId) return

  await checkRoles([Role.MANAGER, Role.FINANCE, Role.LOGISTICS])

  await patch(`/orders/${orderId}/items/update-status`, { data: payload })
}

export async function generateUserPasswordToken (payload: any) {
  const token = await generatePasswordToken(payload)
  return token
}

export async function sendEmail (userId: number) {
  await sendWelcomeEmail(userId)
}

export async function refresh (clientId: number): Promise<ReadResult<Client>> {
  const response = await patch(`/clients/${clientId}/refresh`)
  return response.data as ReadResult<Client>
}
