import { AuthContext } from 'modules/Auth'
import { ForgotPassword } from 'modules/Auth/screens/ForgotPassword'
import { RedefinePassword } from 'modules/Auth/screens/RedefinePassword'
import { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'

interface Props {
  [key: string]: any
}

export function PasswordReset ({ ...rest }: Props) {
  const { token } = useContext(AuthContext)

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const queryParams = new URLSearchParams(location.search)
        const tokenParam = queryParams.get('token')

        if(token) {
          return (
            <Redirect 
              to={{
                pathname: '/',
                state: { from: location }
              }}
            />
          )
        }

        if(tokenParam) {
          return <RedefinePassword token={tokenParam}/>
        } else {
          return <ForgotPassword />
        }
      }}
    ></Route>
  )
}
