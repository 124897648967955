import { ClientStatus } from 'models/clientStatusHistory'

export const documentTypes = [
  { value: 'cnpj', label: 'CNPJ' },
  { value: 'rut', label: 'RUT' }
]

export const types = [
  { value: 'society', label: 'Associação' },
  { value: 'cooperative', label: 'Cooperativa' },
  { value: 'enterprise', label: 'Empresa' }
]

export const segments = [
  { value: 'transportation', label: 'Transporte e Logistica' },
  { value: 'education', label: 'Educação' },
  { value: 'government', label: 'Governamental' },
  { value: 'retail', label: 'Varejo' },
  { value: 'services', label: 'Serviços Gerais' },
  { value: 'trucking', label: 'Caminhões' },
  { value: 'construction', label: 'Materiais e construção' },
  { value: 'energy', label: 'Energia e Utilidades' },
  { value: 'food', label: 'Comida e Bebida' },
  { value: 'healthcare', label: 'Saúde' },
  { value: 'tracking', label: 'Seguro e Rastreamento' },
  { value: 'other', label: 'Outros' }
]

export const statuses: { value: ClientStatus, label: string }[] = [
  { value: 'pending', label: 'Pendente' },
  { value: 'active', label: 'Ativo' },
  { value: 'canceled', label: 'Cancelado' },
  { value: 'not_renewed', label: 'Finalizado' },
  { value: 'overdue', label: 'Inadimplente' },
  { value: 'restricted', label: 'Bloqueado' }
]

export const countries = [
  { value: 1, label: 'Brasil'},
  { value: 2, label: 'Uruguai'},
  { value: 3, label: 'Argentina'},
  { value: 4, label: 'Chile'},
  { value: 5, label: 'México'},
  { value: 6, label: 'Paraguai'},
  { value: 7, label: 'Venezuela'}
]

export const timezones = [
  'America/Sao_Paulo', 'America/Montevideo', 'America/Buenos_Aires',
  'America/Santiago', 'America/Mexico_City', 'America/Asuncion',
  'America/Caracas'
].map((tz) => ({ value: tz, label: tz }))

export function phoneCode (countryId: number | undefined) {
  if (!countryId) return '55'

  const codeMap: Record<number, string> = {
    1: "55",
    2: "598",
    3: "54",
    4: "56",
    5: "52",
    6: "595",
    7: "58"
  }

  return codeMap[countryId]
}