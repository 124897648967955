type StringOrNull = string | null

type Validations = {
  [key: string]: { validate: (value: StringOrNull) => StringOrNull }
}

const validations: Validations = {
  quantity: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return 'O campo não pode ser vazio.'

      const number = parseInt(value)

      if (isNaN(number)) return 'O campo deve ser um número.'

      if (number < 1) return 'O campo não pode ser zero ou negativo.'

      return null
    }
  },
  discount: {
    validate: (value: StringOrNull) => {
      if (!value) return null

      const number = parseInt(value)

      if (isNaN(number)) return 'O campo deve ser um número.'

      return null
    }
  },
  ordered_at: {
    validate: (value: StringOrNull) => {
      if (!value) return null

      return null
    }
  },
  minimum_invoice_amount: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return 'O campo não pode ser vazio.'

      const number = parseInt(value)

      if (isNaN(number)) return 'O campo deve ser um número.'

      if (number < 0) return 'O campo não pode ser negativo.'

      return null
    }
  },
  fine_amount: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return 'O campo não pode ser vazio.'

      const number = parseInt(value)

      if (isNaN(number)) return 'O campo deve ser um número.'

      if (number < 0) return 'O campo não pode ser negativo.'

      return null
    }
  },
}

export function validate (prop: any, value: StringOrNull): string | null {
  if (!(prop in validations)) return null

  const error = validations[prop].validate(value)
  return error
}
