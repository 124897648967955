import { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'

import { classNames } from 'utils'

type Card = {
  id: number
  available: boolean
  value: any
  label: string
  detail: string
  description: string
}

interface Props {
  options: Card[]
  value?: string
  onChange: (value: string) => any
}

export function Cards({value, options, onChange}: Props) {
  const [selected, setSelected] = useState<Card | undefined>(options.find(op => op.value === value))

  useEffect(() => {
    setSelected(options.find(op => op.value === value))
  }, [value, options])

  const onSelect = (option: Card | undefined) => {
    if (!option) return

    setSelected(option)
    onChange(option.value)
  }

  return (
    <RadioGroup value={selected} onChange={(option) => onSelect(option)}>
      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4 animate-fade-in-down">
        {options.map((option) => (
          <RadioGroup.Option
            key={option.id}
            disabled={!option.available}
            value={option}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-emerald-500 ring-1 ring-inset ring-emerald-500' : '',
                !option.available ? 'bg-gray-100 cursor-default' : '',
                'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <div className="flex-1 flex">
                  <div className="flex flex-col">
                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                      {option.label}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                      {option.description}
                    </RadioGroup.Description>
                    <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                      {option.detail}
                    </RadioGroup.Description>
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-emerald-600')}
                  aria-hidden="true"
                />
                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-emerald-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
