import { ReactNode, useState } from 'react'

import { ConfirmationContext, ShowConfirmationDialogParams } from './context'
import { ConfirmationDialog } from './ConfirmationDialog'

export function ConfirmationDialogProvider ({ children }: { children: ReactNode }) {
  const [dialog, setDialog] = useState({
    title: null as ReactNode,
    message: null as ReactNode,
    onConfirm: (() => {}) as () => any,
    onCancel: (() => {}) as () => any,
    open: false
  })

  const closeDialog = () => { 
    setDialog({ 
      ...dialog, open: false , onConfirm: () => {}, onCancel: () => {}
    }) 
  }

  const value = {
    showConfirmationDialog: (params: ShowConfirmationDialogParams) => {

      const onConfirm = () => {
        params.onConfirm()
        closeDialog()
      }

      const onCancel = () => {
        if (params.onCancel) params.onCancel()
        closeDialog()
      }

      setDialog({ ...params, open: true, onConfirm, onCancel })
    }
  }

  return (
    <ConfirmationContext.Provider value={value}>
      {children}
      <ConfirmationDialog { ...dialog } />
    </ConfirmationContext.Provider>
  )
}