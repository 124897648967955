import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { useCallback, useState, useEffect } from 'react'

import {
  InputWithValidationError, RadioListWithDescription,
  ResizibleTextArea, SimpleCustomSelect
} from 'components/FormInputs'
import { Button, Close } from 'components/Buttons'
import { FormSection } from 'components/Form'
import { PageHeader } from 'components/PageHeader'
import { DocumentInput } from '../components/Inputs/Document'
import { IdentificationTable } from '../components/Identification'

import { Errors } from 'types'
import { check } from 'utils/error'
import { validate } from '../validations/client'
import { createClient } from '../services/create'
import { identify, list } from '../services/read'
import { isEmpty, document, phone as phoneMask } from 'utils'
import { Client, DocumentType, Identification } from 'models'
import { cleanDocument, buildCorporateData } from '../helpers/utils'
import { segments, timezones, types } from '../helpers/constants'
import { ReadResult } from 'interfaces/queryOptions'
import { ClientPanel } from '../components/Panels/client'
import { listCountries } from 'modules/Staff/services/api'

export function Create () {
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [phone, setPhone] = useState('')
  const [isDocumentValid, setIsDocumentValid] = useState(false)
  const [identification, setIdentification] = useState<Partial<Identification>>({})

  const [client, setClient] = useState<ReadResult<Client>>()
  const [formData, setFormData] = useState<Partial<Client>>({
    source: 'in_house',
    billing_cycle_day: 10,
    type: 'society',
    segment: 'transportation',
    timezone: timezones[0].value
  })

  interface CountryOption {
    value: number
    label: string
  }

  const [countries, setCountries] = useState<CountryOption[]>([])

  const [errors, setErrors] = useState<Errors>({})

  const [brazilId, setBrazilId] = useState('')

  useEffect(()=>{
    const fetchCountries = async () => {
      const countries = await listCountries({
        attributes: [ 'code', 'name', 'local_name' ]
      })

      setCountries(countries.data.map((country, index) => {
        return {
          value: country.id,
          label: country.attributes.local_name ?? '',
          code: country.attributes.code
        }
      }))

      for (const country of countries.data) {
        if (country.attributes.code === "BRA") {
          setBrazilId(country.id.toString())
          break
        }
      }
    }
    
    fetchCountries()
  },
  []
  )

  const onSave = async () => {
    setIsSaving(true)

    if (validateFields(formData) || check(errors)) {
      setIsSaving(false)
      return
    }

    if (phone && phone !== '') {
      formData.phones = [{
        phone: `${phone.replaceAll(/([^\d]+)/gi, '')}`,
        type: 'Principal'
      }]
    }

    try {
      await createClient(formData)
      toast.success('Cliente criado com sucesso')
      setIsSaving(false)
      history.push('/clients')
    } catch (err: any) {
      toast.error(err.suggestedMessage ?? 'Houve uma falha ao tentar criar o cliente')

      if (err.message.includes('email')) {
        setErrors({ email: 'Email inválido'})
      }

      setIsSaving(false)
    }
  }

  const validateFields = (client: Partial<Client>): boolean => {
    const isFilled = {
      email: (client.hasOwnProperty('email') && client.email !== ''),
      document: (client.hasOwnProperty('document') && client.document !== ''),
      account_name: (client.hasOwnProperty('account_name') && client.account_name !== '')
    }

    const isClientValid = isFilled.document && isFilled.account_name && isFilled.email
    setErrors({
      ...errors,
      document: (!isFilled.document) ? 'O campo deve ser preenchido' : null,
      account_name: (!isFilled.account_name) ? 'O campo deve ser preenchido' : null,
      email: (!isFilled.email) ? 'O campo deve ser preenchido' : null
    })

    return !isClientValid
  }

  const onDocumentClean = useCallback(
    () => {
      setFormData({ ...formData, document: ''})
      setIdentification({})
      setPhone('')
      setClient(undefined)
      setIsDocumentValid(false)
    },
    [formData]
  )

  const onAttributeChange = useCallback(
    (attr: keyof Client, value: any) => {
      const error = validate(attr as any, value)

      setFormData({ ...formData, [attr]: value })
      setErrors({ ...errors, [attr]: error })
    },
    [formData, errors]
  )

  const onPhoneChange = useCallback(
    (value: any) => {
      const error = validate('phone' as any, value)

      setPhone(phoneMask(value))
      setErrors({ ...errors, phone: error })
    },
    [errors]
  )

  const onCountryChange = useCallback(
    (countryId: number) => {
      if (countryId.toString() !== brazilId) {
        setIdentification({})
      }

      const docType: DocumentType = countryId.toString() === brazilId ? 'cnpj' : 'rut'

      setFormData({ ...formData, 'country_id': countryId, document: '', document_type: docType })
      setIsDocumentValid(false)
      setPhone('')
      setClient(undefined)
      setErrors({})
    },
    [formData, brazilId]
  )

  const validateDocument = useCallback(
    async () => {
      const formatedDocument = cleanDocument(formData.document)

      if (formatedDocument === '') return

      try {
        setIsLoading(true)
        const options = {
          filters: [
            { key: 'document', op: 'eq', value: formatedDocument }
          ]
        }
        const result = await list('/clients', options)

        if (result.data.length) {
          setIsDocumentValid(true)
          setIsLoading(false)
          setClient(result.data[0])
          toast.error('Cliente já cadastrado com documento informado')
          return
        }

        if (formData.country_id?.toString() !== brazilId) {
          setIsDocumentValid(true)
          setIsLoading(false)
          return
        }

        const identification = await identify(formatedDocument)
        setIdentification(identification)
        setFormData({
          ...formData,
          email: identification.email,
          fantasy_name: identification.trade_name || identification.corporate_name,
          corporate_data: buildCorporateData(identification)
        })
        setIsDocumentValid(true)
        setIsLoading(false)
      } catch (err: any) {
        setIsLoading(false)
        setIsDocumentValid(false)
        toast.error('Não foi possível encontrar informações sobre o CNPJ')
      }
    },
    [formData, brazilId]
  )

  return (
    <>
    <PageHeader title="Cliente" action="Novo" />

    <div className="flex flex-row justify-between animate-fade-in-down">
      <div className="w-1/2 px-4">
        <FormSection title="Consulta de documento">
          <div>
            <div className="flex flex-row space-x-4 items-end">
              <div className="w-1/4">
                <SimpleCustomSelect
                  label="País"
                  options={countries}
                  value={formData.country_id}
                  onChange={(value) => onCountryChange(value)}
                />
              </div>

              <div className="w-1/2">
                <DocumentInput
                  required
                  name="document"
                  disabled={!formData.country_id || isDocumentValid}
                  value={document(formData.document, formData.document_type)}
                  docType={formData.document_type ? formData.document_type.toUpperCase() : ''}
                  onChange={(value) => onAttributeChange('document', value)}
                  error={errors.document}
                />
              </div>

              <div className="w-1/4">
                <button
                  type="button"
                  disabled={isLoading || !formData.country_id || !formData.document || isDocumentValid}
                  onClick={validateDocument}
                  className={`w-full flex justify-center p-3 rounded-md text-sm font-medium text-white focus:outline-none ${(!formData.country_id || !formData.document || isDocumentValid) ? 'bg-gray-300' : 'bg-emerald-600 hover:bg-emerald-700'}`}
                >
                  { isLoading
                    ? (
                      <div className="flex justify-center items-center">
                        <svg className="animate-spin rounded-full h-5 w-5" viewBox="0 0 24 24" fill="none">
                          <circle className="opacity-25 stroke-current text-white" cx="12" cy="12" r="10" strokeWidth="4"></circle>
                          <path className="opacity-75 fill-current text-white" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </div>
                    )
                    : 'Consultar'
                  }
                </button>
              </div>
            </div>

            <div className="flex flex-row-reverse">
              <div className="w-1/4"></div>
              <div className="mr-3 -mt-0.5">
                <button
                  type="button"
                  className="text-xs text-emerald-600"
                  onClick={onDocumentClean}
                >
                  Limpar documento
                </button>
              </div>
            </div>
          </div>
        </FormSection>

        {
          client && (
            <ClientPanel client={client} />
          )
        }

        <form onSubmit={(e) => { e.preventDefault(); onSave() }} noValidate>
          {
            isDocumentValid && !client && (
              <div className="animate-fade-in-down">
                <FormSection title="Informações de cadastro">
                  <InputWithValidationError
                    label="Nome da conta"
                    name="account_name"
                    value={formData.account_name}
                    onChange={(value) => onAttributeChange('account_name', value)}
                    error={errors.account_name}
                  />

                  <div className="flex flex-row space-x-4">
                    <div className="w-1/2">
                      <InputWithValidationError
                        label="E-mail"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={(value) => onAttributeChange('email', value)}
                        error={errors.email}
                      />
                    </div>
                    <div className="w-1/2">
                      <InputWithValidationError
                        optional="Opcional"
                        label="Telefone"
                        name="phone"
                        type="text"
                        value={phone}
                        onChange={(value) => onPhoneChange(value)}
                        error={errors.phone}
                      />
                    </div>
                  </div>

                  <RadioListWithDescription
                    label="Tipo de cliente"
                    options={types}
                    value={formData.type}
                    onChange={(value) => onAttributeChange('type', value)}
                  />

                  <div className="flex flex-row">
                    <div className="w-2/3">
                      <SimpleCustomSelect
                        label="Segmento"
                        options={segments}
                        value={formData.segment}
                        onChange={(value) => onAttributeChange('segment', value)}
                      />
                    </div>
                  </div>

                  <div className="flex flex-row space-x-8">
                    <div className="w-full">
                      <InputWithValidationError
                        label="Dia de pagamento"
                        name="billing_cycle_day"
                        type="number"
                        value={formData.billing_cycle_day}
                        onChange={(value) => onAttributeChange('billing_cycle_day', value)}
                        error={errors.billing_cycle_day}
                      />
                    </div>

                    <div className="w-full">
                      <SimpleCustomSelect
                        label="Fuso Horário"
                        options={timezones}
                        value={formData.timezone}
                        onChange={(value) => onAttributeChange('timezone', value)}
                      />
                    </div>
                  </div>

                  <ResizibleTextArea
                    optional
                    label="Descrição"
                    name="description"
                    value={formData.description}
                    onChange={(value) => onAttributeChange('description', value)}
                  />
                </FormSection>

                <div className="mt-8 flex justify-end space-x-4">
                  <Close linkTo="/clients" />
                  <Button
                    disabled={isSaving}
                    type="submit"
                    label="Salvar"
                  />
                </div>
              </div>
            )
          }
        </form>
      </div>

      {
        !isEmpty(identification) && (
          <IdentificationTable identification={identification} />
        )
      }
    </div>
    </>
  )
}
