import { ReactNode, useState } from 'react'
import { RadioGroup } from '@headlessui/react'

import { Nature } from 'models'
import { classNames } from 'utils'

type Option = {
  label: string
  value: Nature
}

interface Props {
  label: ReactNode
  onChange?: (value: string) => any
}

const options = [
  { value: Nature.service, label: 'Serviço' },
  { value: Nature.good, label: 'Bem' }
]

export function NatureSelection ({ label, onChange = () => {} }: Props) {
  const [selected, setSelected] = useState(options[0])

  const onSelect = (option: Option) => {
    setSelected(option)
    onChange(option.value)
  }

  return (
    <RadioGroup value={selected} onChange={(option) => onSelect(option)} className="text-left">
      <label className="block text-left text-sm font-medium text-gray-700">{label}</label>
      <div className="bg-white rounded-md -space-y-px mt-1">
        <div className="flex flex-row space-x-4">
          {
            options.map((option) => (
                <div key={option.label} className="w-full">
                  <RadioGroup.Option
                    value={option}
                    className={({ checked }) =>
                      classNames(
                        checked ? 'bg-emerald-50 border-emerald-200' : 'border-gray-300',
                        'rounded-md relative border py-2.5 px-3 flex cursor-pointer focus:outline-none w-28'
                      )
                    }
                  >
                  {({ active, checked }) => (
                    <>
                      <span
                        className={classNames(
                          checked ? 'bg-emerald-600 border-transparent' : 'bg-white border-gray-300',
                          active ? 'ring-2 ring-offset-2 ring-emerald-500' : '',
                          'h-3 w-3 mt-1 cursor-pointer rounded-full border flex items-center justify-center'
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <div className="ml-3 flex">
                        <RadioGroup.Label
                          as="span"
                          className={classNames(checked ? 'text-emerald-900' : 'text-gray-900', 'block text-sm font-medium')}
                        >
                          {option.label}
                        </RadioGroup.Label>
                      </div>
                    </>
                  )}
                  </RadioGroup.Option>
                </div>
          ))}
        </div>
      </div>
    </RadioGroup>
  )
}
